/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/07/2020
 * @Example
 */
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PropTypes from "prop-types";
import React from "react";
import { FormGroup } from 'react-bootstrap';
import InlineSVG from "react-inlinesvg";
import { compose } from "redux";
import Config from "../../../config";
import UserImage from "../../common/user/user-image";
import "./ViewHistory.scss";
import GridContainer from "../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";

const styles = theme => {
    return {
        shadowRow: {
            boxShadow: '0 0.25px 0 #222B45'
        },
        spaceRow: {
            margin: '12px auto'
        },
        fadedColor: {
            color: '#8F9BB3'
        },
        secondTitlle: {
            textTransform: 'uppercase',
            fontSize: 12,
            fontWeight: 'bold'
        },
        boldField: {
            fontSize: 16,
            fontWeight: '500'
        },
        customGrid: {
            margin: '0 -18px',
            '& .dx-datagrid-content': {
                '& td': {
                    backgroundColor: 'transparent !important',
                    '&:first-child': {
                        paddingLeft: '0 !important'
                    }
                }
            }
        }
    };
};

class ViewHistory extends React.PureComponent {
    actionTypeName = (action) => {
        let typeName = '';
        switch (action) {
            case 0:
                typeName = "Tạo mới";
                break;
            case 1:
                typeName = "Điều chỉnh";
                break;
            case 2:
                typeName = "Thêm mới";
                break;
            case 3:
                typeName = "Xóa";
                break;
            default:
                break;
        }
        return typeName;
    };

    renderInfo = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        const createAtDate = data.createdAt._d ? data.createdAt._d : data.createdAt;
        return (
            <div className={"display_row align-center pdt10 pdb10"} style={{ width: '100%' }}>
                <UserImage data={data} src={data.urlUserID} allowHover={false} />
                <div >
                    <div style={{ fontSize: '1.12rem' }} className={classes.boldField}>{data.userID || ""}</div>
                    <div className={classes.fadedColor + " faded_icon"} style={{ display: 'flex', marginTop: 3 }}>
                        <InlineSVG src={require("../../../assets/images/icon-calendar.svg")} style={{ transform: 'translateY(2px)' }} />
                        <div style={{ marginLeft: 7 }}>
                            {Config.convertDate(createAtDate, "", "DD/MM/YYYY, kk:mm:ss", true)}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderDesc = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        const { action, oldValue, newValue, description84, description01 } = data;
        const Language = Config.language || "84";
        return (
            <>
                <div className={classes.fadedColor + " " + classes.secondTitlle}>{this.actionTypeName(action)}</div>
                <div className={classes.boldField} style={{ lineHeight: '28px' }}> {Language === "84" ? description84 : description01}:
                    {action === 1 && <span> {oldValue}<ArrowRightAltIcon
                        style={{ transform: 'translateY(6px)', color: '#8F9BB3' }} />{newValue}
                    </span>}
                    {(action === 2 || action === 0) && <span>{newValue}</span>}
                    {action === 3 && <span>{oldValue}</span>}
                </div>
            </>
        );
    };

    // renderHistoryRows = (dataHistory) => {
    //     if (_.isEmpty(dataHistory)) return;
    //     const { classes } = this.props;
    //     const dataLength = dataHistory.length - 1
    //     return dataHistory.map((data, idx) => {
    //         const { urlUserID, userID, createdAt, action, oldValue, newValue, description84, description01 } = data;
    //         const formatDate = moment(createdAt).format('DD/MM/YYYY HH:mm:ss');
    //         const Language = Config.language || "84";
    //         return (
    //             <Row key={idx} className={classes.spaceRow + " " + (idx !== dataLength ? classes.shadowRow : "")
    //             }>
    //                 <Col xs={3} style={{ paddingLeft: 0 }}>
    //                     <div style={{ display: 'flex' }}>
    //                         <div style={{ width: '20%' }}>
    //                             <UserImage data={data} src={urlUserID} allowHover={false} />
    //                         </div>
    //                         <div style={{ width: '80%' }}>
    //                             <div className={classes.boldField}>{userID}</div>
    //                             <div className={classes.fadedColor + " faded_icon"} style={{ display: 'flex' }}>
    //                                 <InlineSVG src={require("../../../assets/images/icon-calendar.svg")} />
    //                                 <div style={{ transform: 'translateY(3px)' }}>{formatDate}</div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </Col>
    //                 <Col>
    //                     <div className={classes.fadedColor + " " + classes.secondTitlle}>{this.actionTypeName(action)}</div>
    //                     <div className={classes.boldField} style={{ lineHeight: '36px' }}> {Language === "84" ? description84 : description01}:
    //                     {action === 1 ?
    //                             <span> {oldValue}<ArrowRightAltIcon style={{ transform: 'translateY(6px)', color: '#8F9BB3' }} />{newValue}</span>
    //                             : <span>{newValue}</span>}</div>
    //                 </Col>
    //             </Row >
    //         );
    //     })
    // }


    render() {
        const { dataHistory, totalItems, loading, onChangePage, onChangePerPage, skip, limit, classes } = this.props;
        return (
            <FormGroup className={classes.customGrid}>
                <GridContainer
                    style={{ border: 'none' }}
                    dataSource={dataHistory}
                    totalItems={totalItems}
                    itemPerPage={limit ? limit : 10}
                    skipPerPage={skip ? skip : 0}
                    height={500}
                    typePaging={"remote"}
                    loading={loading}
                    pagerFullScreen={false}
                    showRowLines={false}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("ERP_No_data")}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                >
                    <Column cellRender={this.renderInfo} width={349} />
                    <Column cellRender={this.renderDesc} width={"100%"} />
                </GridContainer>
            </FormGroup>
        )
    }
}

ViewHistory.propTypes = {
    dataHistory: PropTypes.any,
    totalItems: PropTypes.number,
    skip: PropTypes.number,
    limit: PropTypes.number,
    loading: PropTypes.bool,

    onChangePage: PropTypes.func,
    onChangePerPage: PropTypes.func,
};

export default compose(withStyles(styles, { withTheme: true })
)(ViewHistory);