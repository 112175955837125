/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/8/2019
 * @Example 
 */

import withStyles from "@material-ui/core/styles/withStyles";
import ErrorIcon from '@material-ui/icons/Error';
import _ from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from 'react';
import NumberFormat from 'react-number-format';
import { compose } from "redux";
import Config from "../../../config/index";
import { TextField } from "../../common/form-material";

const styles = {
    labelNumber: {
        padding: "6.5px 0 !important",
        transform: "translateY(10px) !important"
    },
    requiredLabel: {
        color: '#f44336'
    },
    requiredText: {
        position: 'absolute',
        left: 0,
        bottom: -10
    },
    validateField: {
        '&:not(.disableDot)': {
            '& .MuiInputBase-root': {
                '&:before': {
                    borderBottom: '1px solid #f44336 !important',
                },
                '&:after': {
                    borderBottom: '1px solid #f44336 !important',
                }
            },
            '&:hover': {
                '& .MuiInputBase-root': {
                    '&:before': {
                        borderBottom: '2px solid #f44336 !important'
                    },
                    '&:after': {
                        borderBottom: '2px solid #f44336 !important'
                    }
                }
            },
        }
    },
    errorIcon: {
        position: 'absolute',
        fill: '#f44336',
        fontSize: 28,
        bottom: 6,
        right: 0,
    },
    marginNone: {
        marginTop: '0px !important',
        marginBottom: '0px !important'
    }
};

class NumberFormatField extends PureComponent {
    onChange = (e) => {
        const { onChange } = this.props;
        const value = _.get(e, "value", _.get(e, "target.value", e ? e : ""));
        if (onChange) onChange(value);
    };

    render() {
        const { disabled, value, label, placeholder, fullWidth, prefix, defaultValue, isNumericString = true, thousandSeparator = true,
            displayType = 'input', type = 'text', required, error, classes, decimalScale, margin = "normal", className } = this.props;
        const requiredStatus = required && !_.isEmpty(error) && _.isEmpty(value) ? classes.requiredLabel : '';
        const dotted = disabled && 'disableDot';
        const numberFormatValidate = (required && !_.isEmpty(error) && _.isEmpty(value)) && classes.validateField;
        return (
            <div style={{ position: 'relative' }}>
                {label && <label className={`MuiFormLabel-root ${classes.labelNumber} ${requiredStatus}`}>
                    {Config.lang(`${label}`)} {required && <span style={{ color: '#f44336' }}>*</span>}
                </label>}
                <NumberFormat
                    className={`${numberFormatValidate} ${dotted} ${margin === "none" ? classes.marginNone : ""} ${className}`}
                    type={type}
                    value={value}
                    prefix={prefix}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    customInput={TextField}
                    placeholder={placeholder}
                    displayType={displayType}
                    defaultValue={defaultValue}
                    thousandSeparator={thousandSeparator}
                    isNumericString={isNumericString}
                    decimalScale={decimalScale}
                    onValueChange={this.onChange}
                />
                {requiredStatus && <>
                    <ErrorIcon className={classes.errorIcon} />
                    <span className={`MuiFormHelperText-root Mui-error ${classes.requiredText}`}>{Config.lang(`ERP_Truong_nay_bat_buoc_nhap`)}</span>
                </>}
            </div>
        )
    }
}

NumberFormatField.propTypes = {
    error: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    isNumericString: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    prefix: PropTypes.string,
    placeholder: PropTypes.string,
    displayType: PropTypes.string,
    thousandSeparator: PropTypes.any,
    fullWidth: PropTypes.any,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    decimalScale: PropTypes.number
};

export default compose(withStyles(styles, { withTheme: true })
)(NumberFormatField);