/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 3/28/2020
 * @Example
 */

import React, {PureComponent} from 'react';

import PropTypes from "prop-types";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import InlineSVG from "react-inlinesvg";
import {TextBox} from "devextreme-react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Typography} from "@material-ui/core";
import Combo from "./combo";
import UserImage from "../../user/user-image";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: '100%',
        objectFit: 'contain'
    },
    textBox: {
        width: 'calc(100% - 32px)'
    },
    displayNone: {
        display: "none !important"
    }
};
class Employees extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shrink: Array.isArray(props.value) ? props.value.length > 0 : !!props.value || props.shrink,
            value: props.value,
            searchValue: "",
            dataSource: {
                rows: props.dataSource && !props.autoLoad ? (props.dataSource.rows ? props.dataSource.rows : props.dataSource) : [],
                total: props.dataSource && !props.autoLoad ? (props.dataSource.total || props.dataSource.total === 0 ? props.dataSource.total : (props.totalItems ? props.totalItems : 0)) : 0
            },
            loading: false,
        };
        this.textBoxSearch = null;
        this.filterCboEmployees = {
            timer: null,
            skip: props.skip ? props.skip : 0,
            limit: props.limit ? props.limit : 50
        };
        this.value = props.value;
        const _value = props.value ? (Array.isArray(props.value) ? props.value : [props.value]) : [];
        const key = props.valueExpr ? props.valueExpr : props.keyExpr;
        this.selectedRowKeys = _value.map(v => v[key]);
        // this.searchValue = "";
    }

    loadCboEmployees = (isReset) => {
        const {FormID} = this.props;
        const {pathname} = window.location;
        let _FormID = "";
        if (pathname.indexOf('/') === 0) {
            _FormID = pathname.substring(1, 9);
        }
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: FormID || _FormID,
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.state.searchValue
        };
        this.setState({loading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataSource} = this.state;
                const d = data.rows ? data.rows : data;
                const total = data.total ? data.total : 0;
                this.setState({
                    dataSource: {
                        rows: isReset ? d : dataSource.rows.concat(d),
                        total: total
                    }
                });
            }
        });
    };

    componentDidMount() {
        const {autoLoad, dataSource} = this.props;
        if (typeof dataSource === "undefined" || autoLoad) {
            this.loadCboEmployees();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {keyExpr, valueExpr, totalItems, dataSource, value} = this.props;
        if (!this.props.autoLoad && typeof dataSource !== "undefined" && JSON.stringify(prevProps.dataSource) !== JSON.stringify(dataSource)) {
            this.setState({
                dataSource: {
                    rows: dataSource.rows ? dataSource.rows : dataSource,
                    total: dataSource.total || dataSource.total === 0 ? dataSource.total : totalItems ? totalItems : 0
                }
            });
        }

        //
        let _value = value ? (Array.isArray(value) ? value : [value]) : [];
        const key = valueExpr ? valueExpr : keyExpr;
        const valueKeys = _value && _value.map(v => v[key]);
        if (JSON.stringify(valueKeys) !== JSON.stringify(this.selectedRowKeys)) {
            this.value = value;
            this.selectedRowKeys = valueKeys;
            this.setState({
                value: value
            })
        }
    }

    renderItemCboEmployee = (e) => {
        const {data} = e;
        const {displayExpr,subTextExpr} = this.props;
        if (data) {
            return (
                <div className={"display_row align-center"}>
                    <UserImage data={data} width={32} height={32} allowHover={false} />
                    <div className="cbo-employee-name">
                        {data[displayExpr]}
                        <Typography component={"div"} variant={'body2'}
                                    style={{fontWeight: 200}}
                        >
                            {subTextExpr ? (data[subTextExpr] ? data[subTextExpr] : subTextExpr) : ""}
                        </Typography>
                    </div>
                </div>
            );
        }
    };

    renderFieldCboEmployee = (data) => {
        const {classes, value, multiple, valueExpr, displayExpr, acceptCustomValue, dropdownProps, onHoverAvatar, allowHover} = this.props;
        const {dataSource} = this.state;
        const readOnly = dropdownProps && dropdownProps.readOnly && dropdownProps.readOnly === true;
        const _dataSource = dataSource && dataSource.rows ? dataSource.rows : dataSource;
        if (valueExpr) {
            if (multiple) {
                const _data = [];
                this.value && this.value.forEach((d) => {
                    if (!d[displayExpr]) {
                        const rowData = _dataSource.filter(row => row[valueExpr] === d[valueExpr]);
                        _data.push(rowData && rowData.length > 0 ? rowData[0] : d);
                    } else {
                        _data.push(d);
                    }
                });
                data = _data;
            } else if (typeof data !== "object") {
                const rowData = _dataSource.filter(row => row[valueExpr] === data);
                data = rowData && rowData.length > 0 ? rowData[0] : (value ? value : null);
            }
        }

        if (data && (multiple ? data.length > 0 : true)) {
            if (multiple) {
                return (
                    <div className={"display_row align-right flex-wrap"}
                         style={{width: "100%", height: '100%', paddingBottom: 3}}>
                        {data && data.map((_d, indx) => {
                            // console.log('hao_d', _d);
                            return (
                                <div key={indx} className={"display_row"}>
                                    {_d && <div onMouseOver={() => {
                                        if (onHoverAvatar) onHoverAvatar(data);
                                    }}
                                                onMouseOut={() => {
                                                    if (onHoverAvatar) onHoverAvatar(null);
                                                }}
                                    >
                                        <UserImage data={data} width={32} height={32} allowHover={allowHover} />
                                    </div>}
                                    <TextBox className={"cbo-employee-name " + classes.textBox}
                                             defaultValue={_d && _d[displayExpr] ? _d[displayExpr] : ""}
                                             readOnly={true}
                                    />
                                </div>
                            )
                        })}
                        {acceptCustomValue && !readOnly && <div className={"display_row"}>
                            <InlineSVG className={classes.imgAvatar}
                                       src={require('../../../../assets/images/general/user_default.svg')}/>
                            <TextBox ref={ref => this.textBoxSearch = ref}
                                     className={"cbo-employee-name " + classes.textBox + (data && data.length > 0 ? " " + classes.displayNone : "")}
                                     defaultValue={""}
                                     onKeyUp={(e) => this.onInputChanged(e.event)}
                                     readOnly={true}
                            />
                        </div>}
                    </div>
                );
            } else {
                return (
                    <div className={"display_row align-right"}
                         style={{width: "100%", height: '100%', paddingBottom: 3}}>
                        {data && <div onMouseOver={() => {
                            if (onHoverAvatar) onHoverAvatar(data);
                        }}
                                      onMouseOut={() => {
                                          if (onHoverAvatar) onHoverAvatar(null);
                                      }}
                        >
                            <UserImage data={data} width={32} height={32} allowHover={allowHover} />
                        </div>
                        }
                        <TextBox className={"cbo-employee-name " + classes.textBox}
                                 defaultValue={data && data[displayExpr] ? data[displayExpr] : ""}
                                 readOnly={true}

                        />
                        {/*<div style={{padding: '7px 0'}}>{data && data.EmployeeName ? data.EmployeeName : ""}</div>*/}
                    </div>
                );
            }
        } else {
            return (
                <div className={"display_row align-right pdb5"} style={{width: "100%", height: '100%'}}>
                    <div className={classes.divAvatar}>
                        <InlineSVG className={classes.imgAvatar}
                                   src={require('../../../../assets/images/general/user_default.svg')}/>
                    </div>
                    <TextBox className={"cbo-employee-name " + classes.textBox}
                             value={this.state.searchValue}
                             readOnly={true}
                    />
                    {/*<div style={{padding: '7px 0'}}>{data && data.EmployeeName ? data.EmployeeName : ""}</div>*/}
                </div>
            );
        }
    };

    onInputChanged = (e) => {
        const {skip, limit, autoLoad, dataSource, onInputChanged} = this.props;
        this.setState({searchValue: e.target && e.target.value ? e.target.value : e.value}, () => {
            if (autoLoad || typeof dataSource === "undefined") {
                if (this.filterCboEmployees.timer) clearTimeout(this.filterCboEmployees.timer);
                this.filterCboEmployees.timer = setTimeout(() => {
                    this.filterCboEmployees.skip = skip ? skip : 0;
                    this.filterCboEmployees.limit = limit ? limit : 20;
                    this.loadCboEmployees(true);
                }, 700);
            }
        });
        if (onInputChanged) onInputChanged(e);
    };

    onLoadMore = (e) => {
        const {autoLoad, dataSource, onLoadMore} = this.props;
        if (autoLoad || typeof dataSource === "undefined") {
            this.filterCboEmployees.skip = e.skip;
            this.filterCboEmployees.limit = e.limit;
            this.loadCboEmployees();
        }
        if (onLoadMore) onLoadMore(e);
    };

    onValueChanged = (e) => {
        const {onValueChanged} = this.props;
        this.value = e.value;
        this.selectedRowKeys = e.selectedRowKeys;
        if (onValueChanged) onValueChanged(e);
    };

    render() {
        const {totalItems, label, multiple, shrink, error, disabled,
                  displayExpr, valueExpr, showClearButton, stylingMode, height, className, dropdownProps,
                  InputLabelProps, acceptCustomValue, helperText,
                  required, loading
              } = this.props;
        const {value, dataSource} = this.state;

        const _dataSource = dataSource && dataSource.rows ? dataSource.rows : dataSource;
        const _totalItem = dataSource && dataSource.total ? dataSource.total : totalItems;

        return (
            <React.Fragment>
                <Combo.LoadMore
                    reference={ref => this.dropdown = ref}
                    error={error}
                    acceptCustomValue={acceptCustomValue}
                    dataSource={_dataSource}
                    totalItems={_totalItem}
                    skip={this.filterCboEmployees.skip}
                    className={className}
                    limit={this.filterCboEmployees.limit}
                    displayExpr={displayExpr}
                    keyExpr={valueExpr}
                    valueExpr={valueExpr}
                    value={value}
                    height={height}
                    fieldRender={this.renderFieldCboEmployee}
                    disabled={disabled || this.state.loading}
                    stylingMode={stylingMode ? stylingMode : 'underlined'}
                    shrink={shrink}
                    defaultShrink={shrink}
                    label={label}
                    loading={loading || this.state.loading}
                    multiple={multiple}
                    required={required}
                    helperText={helperText}
                    itemRender={this.renderItemCboEmployee}
                    showClearButton={showClearButton}
                    InputLabelProps={InputLabelProps}
                    dropdownProps={dropdownProps}
                    onValueChanged={this.onValueChanged}
                    onOpened={this.onOpened}
                    onClosed={this.onClosed}
                    onInputChanged={this.onInputChanged}
                    onLoadMore={this.onLoadMore}
                />
            </React.Fragment>
        )
    }
}

Employees.propTypes = {
    error: PropTypes.any,
    helperText: PropTypes.string,
    acceptCustomValue: PropTypes.bool,
    dataSource: PropTypes.any,
    totalItems: PropTypes.number,
    skip: PropTypes.number,
    limit: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.any,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    shrink: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    loading: PropTypes.bool,

    keyExpr: PropTypes.string,
    valueExpr: PropTypes.string,
    displayExpr: PropTypes.string,
    subTextExpr: PropTypes.string,
    FormID: PropTypes.string,
    autoLoad: PropTypes.bool,

    showClearButton: PropTypes.bool,
    stylingMode: PropTypes.string,
    height: PropTypes.any,
    multiple: PropTypes.bool,
    allowHover: PropTypes.bool,

    dropdownProps: PropTypes.any,
    InputLabelProps: PropTypes.any,

    onValueChanged: PropTypes.func,
    onInputChanged: PropTypes.func,
    onLoadMore: PropTypes.func,
};

export default compose(connect(null,
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
    })
), withStyles(styles))(Employees);