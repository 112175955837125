/**
 * @copyright 2019 @ DigiNet
 * @author TAIAU
 * @create 06/22/2020
 * @Example 
 */

import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import GetAppIcon from '@material-ui/icons/GetApp';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Image } from "react-bootstrap";
import Slider from "react-slick";
import Config from "../../../config";
import FileViewer from "../../common/fileviewer/fileviewer";
import Modal from "../../common/modal/modal";
const styles = {
    itemAtt: {
        cursor: 'pointer',
        borderRadius: 100,
        padding: '0 30px 0 8px',
        border: '1px solid lightgrey',
        fontWeight: 400,
        marginRight: 10,
        fontSize: 14,
        height: 24,
        lineHeight: '20px',
        display: 'block',
        marginBottom: 10,
        maxWidth: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            backgroundColor: '#E4E9F2',
            border: '1px solid black'
        }
    },
    iconSize: {
        width: 14
    },
    iconEllipsis: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 18,
        '&:hover': {
            color: 'black',
        }
    },
    iconDowload: {
        cursor: 'pointer',
        position: 'absolute',
        top: -2.5,
        right: 12,
        zIndex: 9,
        height: 30,
        width: 30,
        '&:hover': {
            color: 'black',
        }
    },
    actionPopup: {
        width: 120,
        position: 'absolute',
        top: -30,
        right: -100,
        backgroundColor: '#fff',
        zIndex: 9,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)'
    },
    iconAction: {
        padding: 8
    },
    slickButton: {
        position: "absolute",
        top: '50%',
        webkitTransform: 'translate(0,-50%)',
        msTransform: 'translate(0,-50%)',
        transform: 'translate(0,-50%)',
        zIndex: 2020
    },
    fileViewer: {
        height: "100%",
        minHeight: 600
    }
};

const NextArrow = props => {
    const { onClick } = props;
    return (
        <IconButton style={{ ...styles.slickButton, left: "93.5%" }} size="medium" onClick={onClick} disableFocusRipple={true}>
            <KeyboardArrowRight fontSize="large" />
        </IconButton>
    );
}

const PrevArrow = props => {
    const { onClick } = props;
    return (
        <IconButton style={{ ...styles.slickButton, left: "2%" }} size="medium" onClick={onClick} disableFocusRipple={false}>
            <KeyboardArrowLeft fontSize="large" />
        </IconButton>
    );
}

class Attachments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataOldAttachments: [],
            dataFilesAttachment: [],
            fileIndex: null,
            slideCurrentIndx: 0,
        }
    }

    // shouldComponentUpdate = nextProps => nextProps.data !== this.props.data
    componentDidMount = () => {
        const { data } = this.props;
        this.setState({ dataOldAttachments: data })
    }

    checkAttachmentType(fileName) {
        const { classes } = this.props;
        const limitUrlName = fileName.slice(-4);
        const specialChar = limitUrlName.indexOf(".");
        const reFileName = limitUrlName.slice(specialChar, specialChar.length);
        let iconFile = null;
        switch (reFileName) {
            case ".pdf": // pdf
                iconFile = <Image className={classes.iconSize} src={require('./icons/pdf.svg')} />
                break;
            case ".docx": // word
            case ".doc":
                iconFile = <Image className={classes.iconSize} src={require('./icons/word.svg')} />
                break;
            case ".jpg":// image
            case ".png":
            case ".jpeg":
                iconFile = <Image className={classes.iconSize} src={require('./icons/image.svg')} />
                break;
            case ".xlsx": // excel
            case ".xls":
                iconFile = <Image className={classes.iconSize} src={require('./icons/excel.svg')} />
                break;
            default:
                iconFile = <Image className={classes.iconSize} src={require('./icons/others.svg')} />
                break;
        }
        return iconFile;
    }

    removeFile = (index) => {
        const { dataOldAttachments } = this.state;
        dataOldAttachments.splice(index, 1);
        this.setState({ actionFile: false });
    }

    downloadAttachment = (data) => {
        let element = document.createElement('a');
        element.setAttribute('href', data.URL);
        element.setAttribute('target', '_blank');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    changeSlideAttachment = (indx) => {
        const { slideCurrentIndx } = this.state;
        if (slideCurrentIndx !== indx) {
            this.setState({ slideCurrentIndx: indx });
        }
    };

    render() {
        const { classes, height } = this.props;
        const { slideCurrentIndx, dataFilesAttachment, actionFile, dataOldAttachments } = this.state;

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            lazyLoad: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };

        const currentAtt = dataFilesAttachment[slideCurrentIndx];
        const infoAttachment = {
            filePath: currentAtt && currentAtt.URL ? (currentAtt.URL.indexOf('http') < 0 ? Config.env.cdn + '/' + currentAtt.URL : currentAtt.URL) : "",
            fileName: currentAtt && currentAtt.FileName ? currentAtt.FileName : "",
            fileExt: currentAtt && currentAtt.fileExt ? currentAtt.fileExt : Config.helpers.getExtFile(currentAtt ? currentAtt.FileName : '')
        };

        return (
            <React.Fragment>
                <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexWrap: 'wrap' }}>
                    {dataOldAttachments.length !== 0 && dataOldAttachments.map((item, idx) => {
                        if (idx > 2) return null;
                        return (
                            <div key={idx} style={{ position: 'relative' }}>
                                <span className={classes.itemAtt} onClick={() => this.setState({ dataFilesAttachment: dataOldAttachments, actionFile: !actionFile, fileIndex: idx, slideCurrentIndx: idx })}>{this.checkAttachmentType(item.FileName)} {item.FileName}
                                </span>
                                <IconButton
                                    aria-label="download"
                                    className={classes.iconDowload}
                                    onClick={() => this.downloadAttachment(item)}>
                                    <GetAppIcon />
                                </IconButton>

                                {/* ================= ĐỪNG XÓA CÒN SỬ DỤNG LẠI "NÚT 3 CHẤM (DOWLOAD, VIEW, DELETE)"" ============== */}
                                {/* <Image className={classes.iconEllipsis}
                                    onClick={() => { this.setState({ actionFile: !actionFile, fileIndex: idx }) }}
                                    src={require('./icons/ellipsis.svg')} />
                                {(actionFile && fileIndex === idx) && <div className={classes.actionPopup}>
                                    <span>
                                        <IconButton
                                            aria-label="view"
                                            className={classes.iconAction}
                                            onClick={() => this.setState({ dataFilesAttachment: dataOldAttachments, actionFile: false })}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </span>
                                    <span>
                                        <IconButton
                                            aria-label="download"
                                            className={classes.iconAction}
                                            onClick={() => window.open(item.URL)}
                                        >
                                            <GetAppIcon />
                                        </IconButton>
                                    </span>
                                    <span>
                                        <IconButton
                                            aria-label="delete"
                                            className={classes.iconAction}
                                            onClick={() => this.removeFile(idx)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </span>
                                </div>} */}
                            </div>
                        )
                    })}
                    {dataOldAttachments.length > 3 &&
                        <span
                            onClick={() => { this.setState({ dataFilesAttachment: dataOldAttachments, fileIndex: dataOldAttachments.length - 1, slideCurrentIndx: dataOldAttachments.length - 1 }) }}
                            className={classes.itemAtt}
                            style={{ display: 'inline-flex', textAlign: 'center', width: "auto", padding: 5, justifyContent: "center", alignItems: "center" }}>
                            +{parseInt(dataOldAttachments.length) - 3}
                        </span>
                    }
                </div>
                {
                    dataFilesAttachment && dataFilesAttachment.length > 0 && <Modal className={"modal-review-file"} style={{ zIndex: 1504 }}
                        open={!!dataFilesAttachment}
                        maxWidth={"lg"}
                        fullWidth={true}
                        paperProps={{ style: { height: '100%' } }}
                        type={"file"}
                        download={{
                            filePath: infoAttachment.filePath || "",
                            FileName: infoAttachment.fileName,
                            fileType: infoAttachment.fileExt
                        }}
                        title={Config.lang('ERP_Xem_tap_tin')}
                        onClose={() => { this.setState({ dataFilesAttachment: [] }) }}>
                        <Slider initialSlide={this.state.fileIndex} {...settings} afterChange={this.changeSlideAttachment}>
                            {dataFilesAttachment.map((file, idx) => {
                                const FileName = file && file.FileName ? file.FileName : "";
                                const FileExt = file && file.fileExt ? file.fileExt : Config.helpers.getExtFile(file ? file.FileName : '');
                                const URL = file && file.URL ? (file.URL.indexOf('http') < 0 ? Config.env.cdn + '/' + file.URL : file.URL) : "";
                                return (
                                    <FileViewer
                                        key={idx}
                                        fileName={FileName}
                                        filePath={URL}
                                        fileType={FileExt}
                                        className={classes.fileViewer}
                                        height={height ? height : '100%'} />
                                )
                            })}
                        </Slider>
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

Attachments.propTypes = {
    data: PropTypes.array,
};

export default withStyles(styles)(Attachments);