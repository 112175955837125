import firebase from 'firebase/app';
import 'firebase/messaging';
import "firebase/analytics";
import Config   from "../../config";

const config = {
    apiKey: "AIzaSyBRUdrLjWfQNUA19eANTjN4J83Q9S9O3r0",
    authDomain: "lemon-portal.firebaseapp.com",
    databaseURL: "https://lemon-portal.firebaseio.com",
    projectId: "lemon-portal",
    storageBucket: "lemon-portal.appspot.com",
    messagingSenderId: "707423867216",
    appId: "1:707423867216:web:6e5e0e1ca948b9dbe2dc55",
    measurementId: "G-KST5YWJ9RD"
};

export const fbInitializeApp = () => {
    try {
        firebase.initializeApp(config);
        firebase.analytics();
    } catch (err) {
        if (!/already exists/.test(err.message)) {
            console.error('Firebase initialization error', err.stack)
        }
    }
};

const fbGetToken = (options, cb) => {
    const messaging = firebase.messaging();
    messaging.getToken({vapidKey: 'BIjARMeLQ1rl930gvgy1hh1R8x-IV5pEZorJJnAt0hATVoNb90-chgqCBI6_kr2vhszlmCtxZbgR0g_0fYe9UFU', ...options})
        .then((token) => {
            if (token) {
                if (cb) cb(null, token);
                return token;
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log('No registration token available. Request permission to generate one.');
                // shows on the UI that permission is required
            }
        }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
};

export const fbRequestPermission = async (cb) => {
    try {
    // console.log('====== fbRequestPermission ========');
        if (!firebase.messaging.isSupported()) {
            return;
        }
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register(Config.getRootPath() + "firebase-messaging-sw.js")
                .then(function(registration) {
                    // console.log("Registration successful, scope is:", registration.scope);
                    fbGetToken({serviceWorkerRegistration : registration}, cb);
                })
                .catch(function(err) {
                    fbGetToken(null, cb);
                    // console.log("Service worker registration failed, error:"  , err );
                });
        } else {
            fbGetToken(null, cb);
        }
    } catch (error) {
        if (cb) cb(error);
        console.error(error);
    }
};

export const fbOnMessage = (cb) => {
    // console.log('====== fbOnMessage ========');

    try {
        if (!firebase.messaging.isSupported()) {
            return;
        }

        const messaging = firebase.messaging();
        // console.log('====== fbOnMessage1 ========');

        messaging.onMessage(function(payload) {
            if (cb) cb(null, payload);
        });
    } catch (error) {
        if (cb) cb(error);
        console.error(error);
    }
};
