/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/8/2020
 * @Example
 */


import React from "react";
import {withStyles} from "@material-ui/core";
import propTypes from "prop-types";
import {compose} from "redux";
import Config from "../../../config";
import moment from "moment";
import {Popover} from "devextreme-react";

const styles = {
    divParent: {
        cursor: 'pointer',
        position: 'relative'
    },
    stylePopup: {
        fontSize: '1rem',
        fontWeight: 400
    }
};

class UserName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.userName = null;
    }
    onMouseOver = (e) => {
        this.setState({
            open: true,
        });
    };

    onMouseOut = () => {
        this.setState({
            open: false,
        });
    };

    onPopoverReady = (e) => {
        if (e && e.element && e.element.getElementsByClassName("dx-overlay-content").length > 0) {
            e.element.getElementsByClassName("dx-overlay-content")[0].classList.add("popover-userinfo");
        }
    };

    render() {
        const {classes, data, valueExpr, keyExpr, displayExpr, allowHover, renderItem, renderItemHover, className,
            classNamePopup, style, stylePopup, onClick
        } = this.props;
        let {user} = this.props;
        const {open} = this.state;
        if (data && !user) {
            user = Config.getUser({ [valueExpr]: data[keyExpr] });
        }
        const dateJoined = user ? moment(user.DateJoined).isValid() ? moment(user.DateJoined).format('DD/MM/YYYY') : user.DateJoined : "";

        return (
            <span className={"user-name-c " + classes.divParent + " " + className}
                 style={style}
                 ref={ref => this.userName = ref}
                 onMouseOver={this.onMouseOver}
                 onMouseOut={this.onMouseOut}
                 onClick={onClick}
            >
                {!renderItem && <span>{data && data[displayExpr] ? data[displayExpr] : (user ? user[displayExpr] : "")}</span>}
                {renderItem && (typeof renderItem === "function" ? renderItem(data, user) : renderItem)}
                <Popover
                    target={this.userName}
                    position={"bottom"}
                    maxWidth={300}
                    deferRendering={false}
                    visible={open && user && allowHover}
                    onContentReady={this.onPopoverReady}
                >
                    {!renderItemHover && <div style={stylePopup} className={classes.stylePopup + " " + classNamePopup}>
                        {user && user.DepartmentName && <span>{Config.lang('ERP_Phong_ban') + ': ' + user.DepartmentName}<br/></span>}
                        {user && user.ProjectName && <span>{Config.lang('ERP_Du_an') + ': ' + user.ProjectName}<br/></span>}
                        {user && user.DutyName && <span>{Config.lang('ERP_Chuc_vu') + ': ' +user.DutyName}<br/></span>}
                        {user && user.DateJoined && <span>{Config.lang('ERP_Ngay_vao_lam') + ': ' + dateJoined}</span>}
                    </div>}
                    {renderItemHover && (typeof renderItemHover === "function" ? renderItemHover(user) : renderItemHover)}
                </Popover>
            </span>
        );
    }
}

UserName.defaultProps = {
    valueExpr: "EmployeeID",
    keyExpr: "EmployeeID",
    displayExpr: "EmployeeName",
    allowHover: true
};
UserName.propTypes = {
    width: propTypes.any,
    height: propTypes.any,
    className: propTypes.any,
    classNamePopup: propTypes.any,
    style: propTypes.any,
    stylePopup: propTypes.any,
    /**
     * data: {EmployeeID: "abc", EmployeeName: ""}
     */
    data: propTypes.any,
    valueExpr: propTypes.string,
    keyExpr: propTypes.string,
    displayExpr: propTypes.string,
    allowHover: propTypes.bool,

    renderItem: propTypes.any,
    renderItemHover: propTypes.any,
    onClick: propTypes.func,
};

export default compose(withStyles(styles))(UserName)
