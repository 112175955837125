/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 1/16/2020
 * @Example
 */

import { FormHelperText, Tooltip, Typography, withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from "prop-types";
import React from "react";
import { Col, FormGroup, Image } from "react-bootstrap";
import Dropzone from "react-dropzone";
import InlineSVG from "react-inlinesvg";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../config";
import * as filesActions from "../../../redux/files/file_actions";
import * as mainActions from "../../../redux/main/main_actions";
import CDN from "../../CDN";
import Icons from "../../common/icons/";
import PopoverGrid from "../../grid-container/popover-grid";
import FileViewer from "../fileviewer/fileviewer";
import Modal from "../modal/modal";
const styles = theme => {
    return {
        paper: {
            minHeight: 165,
            paddingBottom: 20,
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'start',
            backgroundColor: 'white',
            border: '1px solid #687799',
        },
        border: {
            top: 0,
            left: 0,
            borderWidth: 2,
            borderRadius: 3,
            borderImageSlice: 2,
            width: '100%',
            height: '100%',
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            position: 'absolute',
            borderStyle: 'dashed',
            justifyContent: 'center',
            borderImageRepeat: 'round',
            borderImageWidth: 'initial',
            borderImageOutset: 'initial',
            borderColor: 'rgb(207, 212, 219)',
            borderImageSource: 'url(https://aui-cdn.atlassian.com/media/border-icon.png)',
            '& .text-muted': {
                color: 'rgb(167, 167, 167)',
            }
        },
        dropzone: {
            top: 0,
            left: 0,
            zIndex: 98,
            width: '100%',
            height: '100%',
            display: 'flex',
            cursor: 'pointer',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
        },
        dropzoneActive: {
            zIndex: 2000,
            backgroundColor: "#eaeaeabf",
        },
        iconAdd: {
            width: 66,
            padding: 13,
            marginBottom: 10,
            borderRadius: '50%',
            border: '2px dashed rgb(207, 212, 219)',
            '& path, polygon': {
                fill: 'rgb(207, 212, 219)'
            }
        },
        button: {
            position: 'absolute',
            top: 10,
            right: 10
        },
        itemContainer: {
            width: '100%'
        },
        items: {
            marginRight: 5,
            marginBottom: 10
        },
        attachItem: {
            borderRadius: 5,
            width: '100%',
            height: '100%',
            display: 'flex',
            cursor: 'pointer',
            overflow: 'hidden',
            position: 'relative',
            flexDirection: 'column',
            boxShadow: '0 0 5px #00000034',
            '& .att-wrap-icon': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '60%'
            },
            '& .att-backdrop-item': {
                top: 0,
                left: 0,
                opacity: 0.4,
                width: '100%',
                height: '100%',
                display: 'none',
                position: 'absolute',
                backgroundColor: '#484848'
            },
            '& .att-info-item': {
                top: 0,
                left: 0,
                padding: 10,
                width: '100%',
                height: '100%',
                display: 'none',
                color: '#FFFFFF',
                position: 'absolute',
                flexDirection: 'column',
                justifyContent: 'space-between',
                '& .att-item-name': {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                },
            },
            '& .att-item-detail': {
                left: 0,
                bottom: 10,
                width: '100%',
                display: 'none',
                padding: '0 10px',
                position: 'absolute',
            },
            '&:hover': {
                '& .att-backdrop-item, .att-info-item, .att-item-detail': {
                    display: 'flex'
                }
            }
        },
        attachItemModule: {
            borderRadius: 4,
            paddingTop: 12,
            height: 'auto',
            zIndex: 99,
            width: 104,
            transform: 'translateY(38px)',
            position: 'relative',
            textAlign: 'center',
            transition: '.2s',
            cursor: 'pointer',
        },
        attachItemBgColor: {
            backgroundColor: theme.palette.hover.main,
        },
        attachmentFile: {
            height: 140,
            top: '-30px',
            margin: '0 15px 15px 0',
            transition: '.2s',
            position: 'relative',
            display: 'inline-flex',
            '&:hover': {
                '& .attachItemModule': {
                    backgroundColor: theme.palette.hover.main,
                },
                '& .btnThreeDot': {
                    opacity: '100%',
                    visibility: 'visible',
                }
            }
        },
        attachItemList: {
            borderRadius: 4,
            height: 40,
            padding: 10,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.hover.main
        },
        attachItemListMobile: {
            position: 'relative',
        },
        attachItemListHoverAction: {
            '&:hover': {
                backgroundColor: theme.palette.hover.main,
                '& .actionBtnListBoard': {
                    opacity: '100%',
                    visibility: 'visible',
                }
            }
        },
        attachToolbar: {
            height: 48,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            padding: '18px 25px',
            justifyContent: 'space-between',
            '& p': {
                color: `${theme.palette.text.primary} !important`,
                fontWeight: `${700} !important`,
            }
        },
        attachedInfo: {
            width: '100%',
            display: "flex",
            justifyContent: "space-around"
        },
        iconDelete: {
            color: '#FFFFFF',
            '&:hover': {
                color: 'red'
            }
        },
        IconExclamation: {
            color: '#fff',
            padding: '3px',
            marginRight: '8px',
            borderRadius: '100%',
            backgroundColor: '#f44336',
        },
        Mark: {
            transform: 'translateY(20%)'
        },
        ColorRequire: {
            color: '#f44336 !important'
        },
        BorderColorRequire: {
            border: '1px solid red'
        },
        hoverBtn: {
            opacity: '0%',
            transition: '.2s',
            visibility: 'hidden',
        },
        btnThreeDot: {
            color: '#231F20',
            transform: 'translate(40px, -68px)',
        },
        iconEllipsis: {
            height: 30,
            width: 32
        },
        actionPopup: {
            top: -5,
            left: 2,
            padding: 4,
            zIndex: 100,
            borderRadius: 4,
            display: 'flex',
            transition: '.2s',
            position: 'absolute',
            backgroundColor: '#fff',
            transform: 'translateX(-15px)',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        },
        iconAction: {
            padding: 8
        },
        colorBlack: {
            color: '#231F20'
        },
        actionBtnListBoard: {
            display: 'flex',
            opacity: 0,
            transition: '.2s',
            visibility: 'hidden',
        },
        actionBtnRightFixed: {
            zIndex: 9,
            position: 'absolute',
            top: 0,
            right: 10
        },
        ellipsisText: {
            overflow: "hidden",
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontWeight: 400
        },
        ellipsisTwoLine: {
            overflow: "hidden",
            fontWeight: 400,
            overflowWrap: 'break-word',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical'
        },
        progressBar: {
            width: '100%',
            height: 10,
            borderRadius: 20,
            zIndex: 99
        }
    };
}
const _sizeLimit = 20000;
class Attachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filePosition: -1,
            isDragOver: false,
            actionFile: false,
            isUploading: false,
            showFileViewer: false,
            viewStyle: 'list',
            formatDateTime: "DD/MM/YYYY HH:mm:ss",
            isOpen: !!props.open,
            fileOpened: {},
            uploadStatus: {},
            _files: [],
            fileIndex: null,
            currentFileClick: null
        };
        this.countFileUploading = 0;
        this.dropzoneRef = null;
        this.timer = {};
        this.timerUpload = {};
        this.uploadStatus = {};
        this.itemFiles = [];
        this.fileDeleted = [];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isUploading, uploadStatus } = this.state;
        if (this.props.open) {
            this.dropzoneRef.click();
        }
        if (uploadStatus && isUploading) {
            const _key = Object.keys(uploadStatus);
            let uploadFinished = true;
            for (let i = 0; i < _key.length; i++) {
                if (uploadStatus[_key[i]].isUploading) {
                    uploadFinished = false;
                    break;
                }
            };
            if (uploadFinished) {
                this.onUploading(false);
                this.onChanged(this.itemFiles);
            }
        }
    }

    openChooseFile = () => {
        if (this.dropzoneRef) {

            this.dropzoneRef.open();
        }
    };

    onDrop = async (files) => {
        const { maxSize, maxUploadOnceAllowed, maxLength, chooseWithoutSave, isAttachInfo } = this.props;
        const oldFiles = this.props.files;
        let messageSize = [];
        let messageName = [];
        const sizeLimit = maxSize ? maxSize : _sizeLimit;
        let _files = this.state._files;
        let _tmpFiles = [];
        if (!Config.profile.UserID) {
            Config.popup.show("INFO", Config.lang("ERP_Khong_the_dinh_kem"));
            return;
        }
        for (let i = 0; i < files.length; i++) {
            if (_files.length + (oldFiles ? oldFiles.length : 0) >= maxLength) {
                Config.popup.show("INFO", Config.lang("ERP_Vuot_qua_so_luong_tap_tin_cho_phep") + "<br/>" + Config.lang("ERP_So_luong_cho_phep") + ": " + maxLength);
                break;
            }
            if ((files[i].size / 1024) > Number(sizeLimit)) {
                messageSize.push(files[i].name);
            } else if (this.checkFileType(files[i].name) === false) {
                messageName.push(files[i].name)
            } else {
                const dtime = new Date().getTime() + i;
                const fileInfo = isAttachInfo ? { CreateUserID: Config.profile.UserID, CreateDate: moment().format("DD/MM/YYYY") } : {}
                const file = {
                    isNew: true,
                    key: dtime,
                    fileName: files[i].name,
                    fileSize: files[i].size,
                    fileExt: files[i].name.split('.').pop(),
                    url: files[i].type.indexOf('image') > -1 ? URL.createObjectURL(files[i]) : null,
                    _file: files[i],
                    ...fileInfo
                };
                _tmpFiles.push(file);
                _files.unshift(file);
            }
        }
        let message = "";
        if (messageSize.length > 0) {
            message += Config.lang("ERP_Tap_tin") + ": " + messageSize.join(",") + "<br>" + Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB <br>';
        }
        if (messageName.length > 0) {
            message += Config.lang("ERP_Tap_tin") + ": " + messageName.join(",") + "<br>" + Config.lang("Dinh_dang_file_khong_hop_le");
        }
        if (message) {
            Config.popup.show("INFO", message);
        }
        this.setState({
            _files: _files,
        }, () => {
            //auto upload file when select files..
            if (_tmpFiles.length > 0) {
                if (!chooseWithoutSave) {
                    this.onUploading(true);
                    const limitUploadedFile = maxUploadOnceAllowed; //allow upload once 5 files
                    this.countFileUploading = 0;
                    _tmpFiles.forEach((_f) => {
                        if (limitUploadedFile && this.countFileUploading >= limitUploadedFile) {
                            this.timerUpload[_f.key] = setInterval(() => {
                                if (this.countFileUploading < limitUploadedFile) {
                                    this.countFileUploading++;
                                    this.__uploadFiles(_f, _f.fileName);
                                    clearInterval(this.timerUpload[_f.key]);
                                }
                            }, 1000);
                        } else {
                            this.countFileUploading++;
                            this.__uploadFiles(_f, _f.fileName);
                        }
                    });
                } else {
                    //select file with not save ....
                    this.onChanged(_tmpFiles);
                }
            }
        });
    };

    __uploadFiles = (file, fileName) => {
        this.setStateUploadStatus(file, {
            success: false,
            isUploading: true,
            _valueProgress: 0,
            errorMessage: ""
        }, () => {
            setTimeout(() => {
                this.setIntervalProgressBar(file);
            }, 200);
            this._uploadFile([file._file], false, (err, data) => {
                this.countFileUploading--;
                if (err) {
                    this.setStateUploadStatus(file, {
                        success: false,
                        isUploading: false,
                        errorMessage: "Error"
                    });
                    return false;
                }
                if (data && data.paths && data.paths.length > 0) {
                    const path = data.paths[0];
                    let _uploadFile = Object.assign(file, path);
                    if (fileName) {
                        _uploadFile.fileName = fileName;
                    }
                    this.itemFiles.push(_uploadFile);
                }
                this.setStateUploadStatus(file, {
                    success: true,
                    _valueProgress: 100,
                }, () => {
                    setTimeout(() => {
                        this.setStateUploadStatus(file, {
                            isUploading: false,
                        });
                    }, 300);
                });
            });
        });
    };

    setStateUploadStatus = (file, stateFile, cb) => {
        if (file) {
            const { uploadStatus } = this.state;
            const _downLink = navigator && navigator.connection && navigator.connection.downlink ? navigator.connection.downlink : 0;
            const _fileSize = file.size / 1024;
            this.uploadStatus[file.key] = {
                ...uploadStatus[file.key],
                ...stateFile
            };
            this.setState({
                uploadStatus: {
                    ...this.uploadStatus,
                    [file.key]: {
                        ...(this.uploadStatus && this.uploadStatus[file.key] ? this.uploadStatus[file.key] : {}),
                        timeUpload: (_fileSize / _downLink),
                        ...stateFile
                    }
                }
            }, () => {
                if (cb) cb();
            });
        }
    };

    setIntervalProgressBar = (file) => {
        if (file) {
            const { uploadStatus } = this.state;
            const _downLink = navigator && navigator.connection && navigator.connection.downlink ? navigator.connection.downlink : 1;
            const _fileSize = file._file ? file._file.size / 1024 : 10;
            const _stateFile = uploadStatus && file.key && uploadStatus[file.key] ? uploadStatus[file.key] : null;
            if (_stateFile) {
                const totalTime = (_fileSize / ((_downLink / 5) * 1024)) * 1000; //milisecond..
                const _timeUpload = 100; //time upload
                const step = _timeUpload * 100 / totalTime;
                let _value = 0;
                this.timer = {
                    [file.key]: setInterval(() => {
                        _value += step;
                        if (_value < 100 && this.state.uploadStatus[file.key]._valueProgress < 100) {
                            this.setStateUploadStatus(file, {
                                _valueProgress: _value
                            });
                        } else {
                            this.clearTimeInterval(file.key);
                        }
                    }, _timeUpload)
                };
            }
        }
    };

    componentWillUnmount() {
        this.clearTimeInterval();
        if (this.timerUpload) {
            const _currentTimer = Object.keys(this.timerUpload);
            _currentTimer.forEach((t) => {
                if (this.timerUpload[t]) {
                    clearInterval(this.timerUpload[t]);
                }
            });
        }
    }

    clearTimeInterval = (key) => {
        if (key) {
            if (this.timer[key]) {
                clearInterval(this.timer[key]);
            }
        } else {
            const _currentTimer = Object.keys(this.timer);
            _currentTimer.forEach((t) => {
                if (this.timer[t]) {
                    clearInterval(this.timer[t]);
                }
            });
        }
    };

    checkFileType = (filename) => {
        const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();
        const allowedExtensions = ['.txt', '.jpg', '.jpeg', '.png', '.xls', '.xlsx', '.doc', '.docx', '.pdf'];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    };

    // onSelectFile = async () => {
    //     const {onChange, chooseWithoutSave, onFileSubmited} = this.props;
    //     const {fileNames} = this.state;
    //     const x = this.inputFiles;
    //     const arrFile = x.files;
    //     const sizeLimit = 10000;
    //     let _fileNames = fileNames;
    //     let fileIndx = fileNames.length;
    //     let messageSize = [];
    //     let messageName = [];
    //     for (let i = 0; i < arrFile.length; i++) {
    //         if ((arrFile[i].size / 1024) > Number(sizeLimit)) {
    //             messageSize.push(arrFile[i].name);
    //             // Config.popup.show("INFO", arrFile[i].name + " " + Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB');
    //         } else if (this.checkFileType(arrFile[i].name) === false) {
    //             messageName.push(arrFile[i].name)
    //             // Config.popup.show("INFO", Config.lang('Dinh_dang_file_khong_hop_le'));
    //         } else {
    //             _fileNames.push({FileName: arrFile[i].name});
    //             this.itemFiles[fileIndx] = arrFile[i];
    //             fileIndx++;
    //         }
    //     }
    //     let message = "";
    //     if (messageSize.length > 0) {
    //         message += Config.lang("ERP_Tap_tin") + ": " +  messageSize.join(",") + "<br>" + Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB <br>';
    //     }
    //     if (messageName.length > 0) {
    //         message += Config.lang("ERP_Tap_tin") + ": " +  messageName.join(",") + "<br>" + Config.lang("Dinh_dang_file_khong_hop_le");
    //     }
    //     if (message) {
    //         Config.popup.show("INFO", message);
    //     }
    //     this.setState({fileNames: _fileNames});
    //
    //     if (onChange) onChange({files: this.itemFiles, deletedFile: this.fileDeleted});
    //
    //     if (onFileSubmited) {
    //         if (chooseWithoutSave) {
    //             this.onSubmited(this.itemFiles);
    //         } else {
    //             this.onUploading(true);
    //             const upload = await this._uploadFile(this.itemFiles, 'multiple');
    //             this.onSubmited(upload && upload.data ? upload.data : null);
    //         }
    //     }
    // };

    _uploadFile = (files, isAsync, cb) => {
        // return await FileUploadSync(files, mode, this.tokenCDN);
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    _removeFileCDN = (params) => {
        CDN.removeFile(params);
    };

    onUploading = (state) => {
        const { onUploading } = this.props;
        if (onUploading) onUploading(state);
        this.setState({ isUploading: state });
    };

    // onSubmited = (files) => {
    //     const {onChange} = this.props;
    //     this.onUploading(false);
    //     if (onChange) onChange({uploadedfiles: files ? files : [], deletedFiles: this.fileDeleted});
    // };

    onAttachment = () => {
        if (this.dropzoneRef) {
            this.dropzoneRef.open();
        }
    };

    onRemoveFile = (file) => {
        if (!file) return false;
        if (!file.isNew) {
            this.fileDeleted.push(file);
        } else {
            if (file.key) {
                let { _files } = this.state;
                _files = _files.filter((f) => {
                    return f.key !== file.key;
                });
                this.itemFiles = this.itemFiles.filter((f) => {
                    return f.key !== file.key;
                });
                this.setState({
                    _files: _files
                });
            }
        }
        this.onChanged(null, true);
    };

    onChanged = (files, isDeleted) => {
        const { onChanged, onDeleted } = this.props;
        if (isDeleted && onDeleted) onDeleted({ deletedFiles: this.fileDeleted });
        console.log(this.fileDeleted)
        if (onChanged) onChanged({ uploadedFiles: files ? files : this.itemFiles, deletedFiles: this.fileDeleted });
    };

    onViewFile = (data) => {
        if (data.url) {
            this.setState({
                fileOpened: {
                    FileName: data.fileName ? data.fileName : "",
                    FileExt: data.fileExt ? data.fileExt : Config.helpers.getExtFile(data.fileName),
                    URL: data.url ? (data.url.indexOf('http') < 0 ? Config.env.cdn + '/' + data.url : data.url) : ""
                },
                showFileViewer: true,
            });
        }
    };

    onCloseViewFile = () => {
        this.setState({ showFileViewer: false })
    };

    onChangeView = () => {
        this.setState({
            viewStyle: this.state.viewStyle === 'list' ? 'module' : 'list'
        })
    };

    _getIconFile = (fileName) => {
        let icon = require('../../../assets/images/files/file.svg');
        try {
            icon = require('../../../assets/images/files/' + fileName);
        } catch (e) {

        }
        return icon;
    };

    onDragOver = () => {
        this.setState({ isDragOver: true });
    };

    loadAttachmentImageFile(filesType, iconSize) {
        let iconFile = null;
        switch (filesType) {
            case "pdf": // pdf
                iconFile = <Image style={{ width: iconSize }} src={require('../../../assets/images/files/attachments/pdf.svg')} />
                break;
            case "docx":
            case "doc": // word
                iconFile = <Image style={{ width: iconSize }} src={require('../../../assets/images/files/attachments/word.svg')} />
                break;
            case "jpg":
            case "png":
            case "jpeg": // image
                iconFile = <Image style={{ width: iconSize }} src={require('../../../assets/images/files/attachments/image.svg')} />
                break;
            case "xlsx":
            case "xls": // excel
                iconFile = <Image style={{ width: iconSize }} src={require('../../../assets/images/files/attachments/excel.svg')} />
                break;
            default:
                iconFile = <Image style={{ width: iconSize }} src={require('../../../assets/images/files/attachments/others.svg')} />
                break;
        }
        return iconFile;
    }

    toggleHoverOut = () => {
        if (!this.state.actionFile) return;
        this.setState(prevState => { this.setState({ actionFile: false }) });
    }

    toggleHoverIn = (idx) => {
        const { fileIndex, actionFile } = this.state;
        if (idx !== fileIndex) {
            this.setState(prevState => { this.setState({ fileIndex: idx }) });
        }
        if (!actionFile) {
            this.setState(prevState => { this.setState({ actionFile: true }) });
        }
    }

    downloadAttachment = (data) => {
        let element = document.createElement('a');
        element.setAttribute('href', data.URL);
        element.setAttribute('target', '_blank');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    handleClick = (stateName, e, filePosition = -1) => {
        this.setState({ [stateName]: e.currentTarget, filePosition });
    };

    handleClose = (stateName) => {
        this.setState({ [stateName]: null });
    };

    popupActionMobile = (file, disabled) => {
        const { currentFileClick } = this.state;
        return (
            <>
                { currentFileClick !== null &&
                    <PopoverGrid
                        id={null}
                        anchorEl={currentFileClick}
                        onClose={() => this.handleClose("currentFileClick")}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        itemProps={
                            <div style={{ padding: 4 }}>
                                <Tooltip title={Config.lang("ERP_Xem")}>
                                    <IconButton
                                        disabled={!_.get(file, "IsAttached", true) || disabled}
                                        aria-label="view"
                                        size={"small"}
                                        onClick={() => this.onViewFile(file)}>
                                        <Icons name={"view"} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={Config.lang("ERP_Tai")}>
                                    <IconButton
                                        disabled={!_.get(file, "IsAttached", true) || disabled}
                                        aria-label="download"
                                        size={"small"}
                                        onClick={() => this.downloadAttachment(file)}>
                                        <Icons name={"download"} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={Config.lang("ERP_Xoa")}>
                                    <IconButton
                                        disabled={!_.get(file, "IsAttached", true) || disabled}
                                        aria-label="delete"
                                        size={"small"}
                                        onClick={() => this.onRemoveFile(file)}>
                                        <Icons name={"delete"} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    />
                }
            </>
        )
    }

    popupActionWeb = (file, disabled) => {
        const { classes } = this.props;
        return (
            <div className={(!Config.isMobile ? classes.actionBtnListBoard : classes.actionBtnRightFixed) + " actionBtnListBoard " + classes.actionBtnListBoardGeneral} style={{ marginRight: !Config.isMobile ? 10 : 0, }}>
                <IconButton style={{ marginLeft: Config.isMobile ? 10 : 22 }}
                    disabled={!_.get(file, "IsAttached", true) || disabled}
                    aria-label="view" size={"small"}
                    onClick={() => this.onViewFile(file)}>
                    <Icons name={"view"} />
                </IconButton>
                <IconButton style={{ marginLeft: Config.isMobile ? 10 : 22 }}
                    disabled={!_.get(file, "IsAttached", true) || disabled}
                    aria-label="download"
                    size={"small"} onClick={() => this.downloadAttachment(file)}>
                    <Icons name={"download"} />
                </IconButton>
                <IconButton style={{ marginLeft: Config.isMobile ? 10 : 22 }}
                    disabled={!_.get(file, "IsAttached", true) || disabled}
                    aria-label="delete" size={"small"}
                    onClick={() => this.onRemoveFile(file)}>
                    <Icons name={"delete"} />
                </IconButton>
            </div>
        )
    }

    render() {
        const { classes, disabled, alwayShowArea, files, uploading, hidden, error, required } = this.props;
        const { isDragOver, viewStyle, _files, showFileViewer, fileOpened, uploadStatus, actionFile, fileIndex, formatDateTime, filePosition } = this.state;
        const convertFiles = Config.helpers.getFileInfomations(files);
        let dataFiles = convertFiles ? _files.concat(convertFiles) : _files;
        return (
            <React.Fragment>
                <Modal style={{ zIndex: 1504 }} open={showFileViewer} maxWidth={"lg"} fullWidth={true}
                    paperProps={{ style: { height: '100%' } }}
                    title={Config.lang('ERP_Xem_tap_tin')}
                    type={"file"}
                    download={{
                        filePath: fileOpened.URL || "",
                        FileName: fileOpened.FileName,
                        fileType: fileOpened.FileExt
                    }}
                    onClose={this.onCloseViewFile}>
                    <FileViewer fileName={fileOpened.FileName} filePath={fileOpened.URL} fileType={fileOpened.FileExt} height={'100%'} />
                </Modal>
                <FormGroup>
                    <Paper style={{ display: (typeof alwayShowArea === "undefined" || alwayShowArea || _files.length > 0) && !hidden ? 'flex' : 'none' }}
                        elevation={0}
                        className={classes.paper + " " + (error && _.isEmpty(_files) ? classes.BorderColorRequire : "")}
                    >
                        <div className={classes.attachToolbar}>
                            <Typography style={{ fontSize: 12 }} className={"text-uppercase " + (error && _.isEmpty(_files) ? classes.ColorRequire : "")}>{Config.lang("ERP_Dinh_kem")}</Typography>
                            {required ? <span style={{ paddingLeft: '4px' }} className={classes.ColorRequire}>*</span> : ""}
                            <div style={{ marginLeft: 'auto', zIndex: 999 }}>
                                {error && _.isEmpty(_files) ? <span className={classes.IconExclamation}><PriorityHighIcon className={classes.Mark} fontSize="small" /></span> : ""}
                                <IconButton aria-label="order" size={"small"} onClick={this.onChangeView}>
                                    <Image src={viewStyle === "list" ? require('../../../assets/images/files/attachments/iconListView.svg') : require('../../../assets/images/files/attachments/iconModuleView.svg')} />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '100%' }}>
                            {/*<div className={classes.border + " " + (error && _.isEmpty(_files) ? classes.BorderColorRequire : "")} />*/}
                            <span className={"display_col align-center valign-middle"} style={{ width: '100%', height: '100%' }}>
                                <Dropzone ref={ref => this.dropzoneRef = ref}
                                    onDragOver={this.onDragOver}
                                    style={{ ...(isDragOver ? { zIndex: 1000 } : {}) }}
                                    disabled={disabled}
                                    accept={".txt, image/jpg, image/jpeg, image/png, .xls, .xlsx, .doc, .docx, application/pdf"}
                                    onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                        <>
                                            <div {...getRootProps({ className: classes.dropzone + (isDragActive ? " " + classes.dropzoneActive : "") })}>
                                                <input {...getInputProps()} />
                                            </div>
                                            {dataFiles && dataFiles.length < 1 &&
                                                <React.Fragment>
                                                    <InlineSVG src={require('../../../assets/images/general/add_circle.svg')} className={classes.iconAdd} />
                                                    <span style={{ fontStyle: 'italic', fontSize: 12 }} >{Config.lang("ERP_Keo_va_tha_tap_tin_dinh_kem_vao_day")}</span>
                                                </React.Fragment>
                                            }
                                        </>
                                    )}
                                </Dropzone>
                            </span>
                        </div>
                        {viewStyle === "module" && <Col style={{ margin: '0 16px' }}>
                            {dataFiles.map((file, idx) => {
                                const _uploadStatus = uploadStatus && file.key && uploadStatus[file.key] ? uploadStatus[file.key] : null;
                                let createDate = _.get(file, "CreateDate", "");
                                let createUserID = _.get(file, "CreateUserID", "");
                                let isAttachedInfo = createUserID !== "" || createDate !== "";
                                let employeeName = isAttachedInfo && _.get(Config.getUser({ EmployeeID: createUserID }), "EmployeeName", "");
                                const hoverAttachmentBtn = Config.isMobile ? '' : classes.hoverBtn;
                                return (
                                    <div className={classes.attachmentFile} key={idx} onMouseLeave={() => this.toggleHoverOut()}>
                                        {(fileIndex === idx) && actionFile &&
                                            <div className={classes.actionPopup}
                                                onMouseLeave={() => this.toggleHoverOut()}>
                                                <IconButton
                                                    aria-label={"view"}
                                                    size={"small"}
                                                    className={classes.iconAction}
                                                    onClick={() => this.onViewFile(file)}>
                                                    <Icons name={"view"} />
                                                </IconButton>
                                                <IconButton
                                                    aria-label={"download"}
                                                    size={"small"}
                                                    className={classes.iconAction}
                                                    onClick={() => this.downloadAttachment(file)} >
                                                    <Icons name={"download"} />
                                                </IconButton>
                                                <IconButton
                                                    aria-label={"delete"}
                                                    size={"small"}
                                                    className={classes.iconAction}
                                                    onClick={() => this.onRemoveFile(file)}>
                                                    <Icons name={"delete"} />
                                                </IconButton>
                                            </div>
                                        }
                                        <div className={`${classes.attachItemModule} attachItemModule  ${Config.isMobile ? classes.attachItemBgColor : ''}`}>
                                            <div className={classes.iconSize}>{this.loadAttachmentImageFile(file.fileExt, 50)}
                                                {(!_uploadStatus || (_uploadStatus && !_uploadStatus.isUploading)) &&
                                                    <div className={hoverAttachmentBtn + " btnThreeDot " + classes.btnThreeDot} onMouseEnter={() => this.toggleHoverIn(idx)}>
                                                        <InlineSVG className={classes.iconEllipsis} src={require('../../../assets/images/files/attachments/ellipsisHover.svg')}
                                                            {...(actionFile ? { style: { fill: '#231F20' } } : {})}
                                                        />
                                                    </div>}
                                            </div>
                                            {((_uploadStatus && _uploadStatus.isUploading === false) || _.isNull(_uploadStatus)) && <span style={{ transform: 'translateY(-24px)', padding: '0 10px' }} className={"display_row"}>
                                                {/* eslint-disable */}
                                                <Tooltip title={file.fileName} aria-label={file.fileName}>
                                                    {file.url ? (
                                                        <a className={classes.ellipsisTwoLine} style={{ color: "#222B45" }}
                                                            onClick={() => this.onViewFile(file)}>{file.fileName}</a>
                                                    ) : (
                                                            <span className={classes.ellipsisTwoLine}>{file.fileName}</span>
                                                        )}
                                                </Tooltip>
                                                {isAttachedInfo &&
                                                    <Tooltip title={employeeName} aria-label={employeeName}>
                                                        <div className={classes.ellipsisTwoLine}>
                                                            <span>{employeeName}</span>
                                                        </div>
                                                    </Tooltip>}
                                                {/* eslint-enable */}
                                            </span>}
                                        </div>
                                        { _uploadStatus && _uploadStatus.isUploading && <div className={"display_row align-center align-between"} style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                                            <LinearProgress variant="determinate" value={_uploadStatus._valueProgress} className={classes.progressBar} />
                                        </div>}
                                    </div>
                                )
                            })}
                        </Col>}
                        {viewStyle === "list" && <div style={{ display: 'block', width: '100%' }}>
                            {dataFiles.map((file, idx) => {
                                const _uploadStatus = uploadStatus && file.key && uploadStatus[file.key] ? uploadStatus[file.key] : null;
                                let createDate = _.get(file, "CreateDate", "");
                                let createUserID = _.get(file, "CreateUserID", "");
                                let isAttachedInfo = createUserID !== "" || createDate !== "";
                                const { UserNameU } = Config.profile;
                                const formatTime = moment(createDate, moment.ISO_8601, true).isValid() ? moment(createDate).format('DD/MM/YYYY HH:mm:ss') : createDate;
                                const employeeName = isAttachedInfo ? _.get(Config.getUser({ UserID: createUserID }), "UserNameU", "") : UserNameU;
                                const currentDateTime = moment().format(formatDateTime);
                                const timeUploaded = formatTime || currentDateTime;
                                return (
                                    <Col key={idx} xs={12} className={"mgb15"} style={{ height: 40, zIndex: 99, width: isAttachedInfo ? "100%" : "", paddingRight: !Config.isMobile ? 50 : 'auto' }}>
                                        <div className={`${Config.isMobile ? classes.attachItemListMobile : classes.attachItemListHoverAction} ${classes.attachItemList}`}>
                                            <div style={{ width: '100%', justifyContent: Config.isMobile ? '' : 'space-between' }} className={"display_row"}>
                                                {/* eslint-disable */}
                                                <Tooltip title={file.fileName} aria-label={file.fileName}>
                                                    <div className={classes.ellipsisText} style={{ width: '50%', transform: Config.isMobile ? 'translateY(2px)' : 'translateY(4px)', color: '#222B45', marginRight: Config.isMobile ? '3%' : '0' }}>
                                                        <span style={{ marginRight: 16, display: 'inline-block', transform: 'translateY(-2px)' }}>{this.loadAttachmentImageFile(file.fileExt, 15)}</span>
                                                        <span style={{ fontWeight: 400, fontSize: 16 }}>{file.fileName}{file.fileSize && <span style={{ fontWeight: 500 }}>&nbsp;{file.fileSize ? '(' + (file.fileSize / 1024 / 1024).toFixed(1) + 'MB)' : ""}</span>}</span>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip title={`${employeeName} - ${timeUploaded}`} aria-label={employeeName}>
                                                    <div className={classes.ellipsisText} style={{ width: '25%', transform: 'translateY(4px)', color: '#687799', display: 'inline-block' }}>
                                                        <span>{employeeName}</span> - <span>{timeUploaded}</span>
                                                    </div>
                                                </Tooltip>
                                                {/* eslint-enable */}

                                                {_uploadStatus && _uploadStatus.isUploading && <div style={{ width: '25%', marginTop: 8 }}>
                                                    <LinearProgress variant="determinate" value={_uploadStatus._valueProgress} className={classes.progressBar} />
                                                </div>}
                                                {(!_uploadStatus || (_uploadStatus && !_uploadStatus.isUploading)) && Config.isMobile &&
                                                    <div className={(!Config.isMobile ? '' : classes.actionBtnRightFixed)} style={{ display: 'flex', height: '100%' }}>
                                                        <IconButton
                                                            aria-label={"more"}
                                                            size={"small"}
                                                            aria-describedby={'file_actions'}
                                                            className={classes.divIconItem}
                                                            onClick={(e) => this.handleClick('currentFileClick', e, idx)}
                                                            disabled={!_.get(file, "IsAttached", true) || disabled}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    </div>}
                                                {!Config.isMobile && this.popupActionWeb(file, disabled)}
                                                {(filePosition === idx && Config.isMobile) && this.popupActionMobile(file, disabled)}
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </div>}
                        {(uploading) && <div className="display_row align-center valign-middle loading">
                            <CircularProgress />
                        </div>}
                        {error && _.isEmpty(_files) ? <FormHelperText className={classes.ColorRequire}>{error}</FormHelperText> : ""}
                    </Paper>
                </FormGroup>
            </React.Fragment>
        );
    }
}

Attachments.defaultProps = {
    maxUploadOnceAllowed: 0
};
Attachments.propTypes = {
    uploading: PropTypes.bool,
    chooseWithoutSave: PropTypes.bool,
    disabled: PropTypes.bool,
    files: PropTypes.any, //[{URL: "", FileName: "aaaa.xls", FileExt: "xls"}]
    maxLength: PropTypes.number,
    alwayShowArea: PropTypes.bool,
    maxSize: PropTypes.number,
    hidden: PropTypes.bool,
    maxUploadOnceAllowed: PropTypes.number,
    isAttachInfo: PropTypes.bool,
    onUploading: PropTypes.func,
    onChanged: PropTypes.func,
    onDeleted: PropTypes.func,
    onFileSubmited: PropTypes.func,
};

export default compose(connect(null,
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        filesActions: bindActionCreators(filesActions, dispatch),
    }), null, { forwardRef: true }
), withStyles(styles))(Attachments);
