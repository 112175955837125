/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/8/2019
 * @Example 
 */

import React, {PureComponent} from 'react';

import PropTypes from "prop-types";
import Config from "../../../config";
import CheckboxM from "@material-ui/core/Checkbox";
import {FormControlLabel, FormHelperText} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import "./_form.scss";

class Checkbox extends PureComponent {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {label, checked, value, disabled, color, fullWidth, required, isSubmitting, error, helperText,
            className, style, labelProps, controlProps,
            onChange} = this.props;

        return (
            <FormControl error={!disabled && (error || (required && isSubmitting && !checked))}
                         className={((required && isSubmitting && !checked) ? "Mui-error " : "") + className}
                         variant={"outlined"}
                         fullWidth={fullWidth}>
                <FormControlLabel
                    {...labelProps}
                    style={style}
                    control={
                        <CheckboxM
                            {...controlProps}
                            disabled={disabled}
                            checked={checked}
                            onChange={(e, data) => {
                                if (onChange) onChange(e, data);
                            }}
                            value={value}
                            required={required}
                            color={color}
                        />
                    }
                    label={(required ? " *" : "") + label}
                >
                </FormControlLabel>
                <FormHelperText>{!disabled && required && isSubmitting && !checked ? Config.lang("ERP_Truong_nay_bat_buoc_chon") : helperText}</FormHelperText>
            </FormControl>
        )
    }
}

Checkbox.propTypes = {
    isSubmitting: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    fullWidth: PropTypes.any,
    required: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.any,
    labelProps: PropTypes.any,
    controlProps: PropTypes.any,

    onChange: PropTypes.func,
    onError: PropTypes.func
};

export default Checkbox;