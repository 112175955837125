/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 05/08/2020
 * @Example
 */
import React, { Component } from "react";
import { Popover as GridPopover } from "@material-ui/core";
import PropTypes from "prop-types";

class PopoverGrid extends Component {
  onClose = () => {
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    const {
      id,
      anchorEl,
      itemProps,
      anchorOrigin,
      transformOrigin,
      PaperProps,
      style,
      className,
      children
    } = this.props;
    const open = Boolean(anchorEl);
    return (
      <GridPopover
        PaperProps={PaperProps}
        style={style}
        className={className}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={this.onClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
          ...anchorOrigin,
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
          ...transformOrigin,
        }}
      >
        {itemProps ? itemProps : children}
      </GridPopover>
    );
  }
}

PopoverGrid.propsTypes = {
  id: PropTypes.any,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  itemProps: PropTypes.any,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  vertical: PropTypes.oneOf(["bottom", "center", "top"]),
  horizontal: PropTypes.oneOf(["left", "center", "right"]),
  PaperProps: PropTypes.any,
  style: PropTypes.any,
  className: PropTypes.string,
};

export default PopoverGrid;
