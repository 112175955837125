/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 06/17/2020
 * @Example 
 */
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from '@material-ui/icons/Search';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../config/index";
import * as generalActions from "../../../redux/general/general_actions";
import GridContainer from "../../grid-container/grid-container";
import ButtonGeneral from "../button/button-general";
import Modal from "../modal/modal";

const styles = {
    circleBtn: {
        borderRadius: '100%',
        minWidth: 0
    },
    searchTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#222B45',
        fontSize: '12px',
        marginTop: 17
    }
};
class SelectIDNameModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalLoaded: false,
            dataGrid: [],
            totalDataGrid: 0
        };
        this.tmpSelectedRowData = [];
        this.tmpSelectedRowKeys = [];
        this.changePage = false;
        this.filter = {
            limit: 10,
            skip: 0,
            StrSearch: "",
        }
        this.timer = null;
    }

    componentDidMount = async () => {
        const { dataSelectModal, selectedRow } = this.props;
        const { dataGrid } = this.state;
        const { keyExpr } = this.getActionName();
        if (keyExpr === "ObjectID" && selectedRow) this.tmpSelectedRowKeys = selectedRow;
        if (!_.isEmpty(dataSelectModal.rows) && _.isEmpty(dataGrid)) {
            const dataKeyName = Object.keys(dataSelectModal.rows[0]);
            if (dataKeyName.includes(keyExpr)) { //Data cũ thì xài lại không cần load api
                this.setState({
                    dataGrid: dataSelectModal.rows,
                    totalDataGrid: dataSelectModal.total
                })
            } else {
                this.loadGrid(true);
            }
        } else {
            this.loadGrid(true);
        }
    };

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }

    getActionName = () => {
        const { modalType } = this.props;
        let actionName = "";
        let titleName = "";
        let searchName = "";
        let keyExpr = "";
        switch (modalType) {
            case "Inventory":
                actionName = "getInventories";
                searchName = Config.lang("ERP_Tim_kiem_hang_hoa");
                titleName = Config.lang("ERP_Danh_sach_hang_hoa");
                keyExpr = "InventoryID";
                break;
            case "Company":
                actionName = "getObject";
                searchName = Config.lang("ERP_Tim_kiem_cong_ty");
                titleName = Config.lang("ERP_Danh_sach_cong_ty");
                keyExpr = "ObjectID";
                break;
            default:
                break;
        }
        return { actionName, titleName, searchName, keyExpr };
    }

    loadGrid = (isReset = false) => {
        if (!isReset) {
            this.changePage = true;
        };
        const { formID: FormID = "" } = this.props;
        const { limit, skip, StrSearch } = this.filter;
        const { actionName } = this.getActionName();
        const params = {
            FormID,
            StrSearch,
            skip,
            limit
        };
        if (actionName === "getObject") {
            const { ObjectTypeID } = this.props;
            params.ObjectTypeID = ObjectTypeID || "CC";
        }
        this.setState({ modalLoaded: true })
        this.props.generalActions[actionName](params, (errors, data) => {
            this.setState({ modalLoaded: false })
            if (errors) {
                let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data && data.rows) {
                this.setState({
                    dataGrid: data.rows ? data.rows : data,
                    totalDataGrid: data.total
                });
            }
        });
    };

    onFilter = () => {
        this.filter.skip = 0;
        this.loadGrid(true);
        this.changePage = true;
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    onSelect = () => {
        const selectedRowKeys = this.tmpSelectedRowData;
        const { onChosen } = this.props;
        if (onChosen) onChosen(selectedRowKeys);
    };

    handleChecked = (currentSelectedRowKeys, keyExpr, mode) => {
        if (_.isEmpty(this.tmpSelectedRowData) && !_.isEmpty(this.tmpSelectedRowKeys)) this.tmpSelectedRowKeys = [];
        currentSelectedRowKeys.forEach((val) => {
            if (this.tmpSelectedRowData.indexOf(val) < 0 && !this.tmpSelectedRowKeys.includes(val[keyExpr])) {
                this.tmpSelectedRowData.push(val);
                this.tmpSelectedRowKeys.push(val[keyExpr]);
            }
        });
    }

    handleUnChecked = (currentDeselectedRowKeys, keyExpr) => {
        this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
        });
        this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e) < 0;
        });
    }

    setSelectedRow = (e) => {
        const { keyExpr } = this.getActionName();
        const currentSelectedRowKeys = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        const { selectedRowKeys, mode = "multiple" } = this.props;
        if (this.changePage) {
            if (currentSelectedRowKeys.length > 0) {
                this.handleChecked(currentSelectedRowKeys, keyExpr, mode);
            }
            this.changePage = false;
        } else {
            if (currentDeselectedRowKeys.length > 0) {// UnSelect
                this.handleUnChecked(currentDeselectedRowKeys, keyExpr);
            }
            else if (currentSelectedRowKeys.length > 0) {
                if (mode === "multiple") { //Multiple Select
                    this.handleChecked(currentSelectedRowKeys, keyExpr, mode);
                }
                if (mode === "single") { //Select one only
                    currentSelectedRowKeys.forEach((val) => {
                        this.tmpSelectedRowData = [val]
                        this.tmpSelectedRowKeys = [val[keyExpr]];
                    });
                }
            }
        }
        selectedRowKeys(e.selectedRowKeys);
    }

    onDbCellClick = (e) => {
        const { onChosen, mode } = this.props;
        if (onChosen && mode === "single") onChosen([e.data]);
    }

    columnFieldName = () => {
        const { modalType } = this.props;
        let columnData = [];
        switch (modalType) {
            case "Inventory":
                columnData = [
                    {
                        caption: Config.lang("ERP_Ma_hang"),
                        dataField: "InventoryID"
                    },
                    {
                        caption: Config.lang("ERP_Ten_hang"),
                        dataField: "InventoryNameU"
                    }
                ]
                break;
            case "Company":
                columnData = [
                    {
                        caption: Config.lang("ERP_Ma_cong_ty"),
                        dataField: "ObjectID"
                    },
                    {
                        caption: Config.lang("ERP_Ten_cong_ty"),
                        dataField: "ObjectNameU"
                    }
                ]
                break;
            default:
                break;
        }
        return { columnData };
    }

    render() {
        const { onClose, classes, openModal, maxWidth, listPerPage, mode = "multiple" } = this.props;
        const { dataGrid, totalDataGrid, modalLoaded } = this.state;
        const { titleName, searchName, keyExpr } = this.getActionName();
        const { columnData } = this.columnFieldName();
        return (
            <React.Fragment>
                <Modal open={openModal} maxWidth={maxWidth ? maxWidth : 'sm'} fullWidth={true}>
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between"} style={{ width: "100%", margin: '4px 0', flexWrap: 'wrap' }}>
                            <div className={"display_row align-center"}>
                                <Typography variant="h6" className={"mgr10 text-uppercase"}>{titleName}</Typography>
                            </div>
                            <div className={"display_row"} {...(window.innerWidth < 576 ? { style: { width: '100%', justifyContent: 'flex-end' } } : {})}>
                                <ButtonGeneral name={Config.lang("ERP_Dong1")}
                                    size={"large"}
                                    typeButton={"cancel"}
                                    disabled={modalLoaded}
                                    style={{ textTransform: 'uppercase', marginRight: 15 }}
                                    onClick={onClose} />
                                {mode === "multiple" &&
                                    <ButtonGeneral name={Config.lang("ERP_Chon")}
                                        color={"info"}
                                        size={"large"}
                                        variant={"contained"}
                                        loading={modalLoaded}
                                        disabled={_.isEmpty(this.tmpSelectedRowKeys)}
                                        style={{ textTransform: 'uppercase' }}
                                        onClick={this.onSelect} />
                                }
                            </div>
                        </div>
                    </Modal.Title>
                    <Modal.Content>
                        <span className={classes.searchTitle}>{searchName}</span>
                        <FormGroup>
                            <TextField
                                placeholder={searchName}
                                variant={"outlined"}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <Button className={classes.circleBtn} onClick={this.onFilter}>
                                            <SearchIcon />
                                        </Button>
                                    )
                                }}
                                onChange={(e) => {
                                    clearTimeout(this.timer);
                                    let value = e.target.value;
                                    this.timer = setTimeout(() => {
                                        this.filter.StrSearch = value;
                                        this.loadGrid();
                                    }, [1000])
                                }}
                                fullWidth
                            />
                        </FormGroup>
                        <FormGroup>
                            <GridContainer
                                totalItems={totalDataGrid}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                dataSource={dataGrid}
                                loading={modalLoaded}
                                keyExpr={keyExpr}
                                gridProps={{
                                    style: { maxHeight: 410 }
                                }}
                                pagerFullScreen={false}
                                showBorders={false}
                                columnAutoWidth={true}
                                typeShort={window.innerWidth < 768}
                                height={"calc(100vh - 300px)"}
                                selection={{
                                    allowSelectAll: mode === "multiple",
                                    mode: mode,
                                    selectAllMode: "",
                                    showCheckBoxesMode: "always"
                                }}
                                allowColumnResizing={true}
                                listPerPage={listPerPage}
                                selectedRowKey={this.tmpSelectedRowKeys}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onSelectionChanged={(e) => mode === "multiple" && this.setSelectedRow(e)}
                                onDbCellClick={this.onDbCellClick}
                            >
                                {columnData && columnData.map((e, idx) => {
                                    return (
                                        <Column
                                            key={idx}
                                            caption={e.caption}
                                            dataField={e.dataField}
                                            width={200}
                                        />
                                    );
                                })}
                            </GridContainer>
                        </FormGroup>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

SelectIDNameModal.defaultProps = {
    mode: 'multiple'
}

SelectIDNameModal.propTypes = {
    maxWidth: PropTypes.string,
    formID: PropTypes.string,
    selectedRow: PropTypes.any,
    listPerPage: PropTypes.any,
    onClose: PropTypes.func,
    onChosen: PropTypes.func,
    selectedRowKeys: PropTypes.func,
    mode: PropTypes.string,
}

export default compose(connect((state) => ({
    dataSelectModal: state.general.dataSelectModal
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(SelectIDNameModal);