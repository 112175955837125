/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React from 'react';
import {Row} from "react-bootstrap";
import Config from "../../config";
import {browserHistory} from "react-router";

import {Animated} from "react-animated-css";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as homeActions from "../../redux/home/home_actions";
import IconMenu from "diginet-core-ui/icons/menu/v2";

class Dashboard extends React.Component {

    constructor(props){
      super(props);
        this.state = {
            isTab: Config.menuType,
        }
    };

    // loadPermission = async () => {
    //     await this.props.generalActions.getPermission("W75F2000", (isPer) => {
    //         this.setState({ iPermission: isPer });
    //     });
    // };

    // getMyLocation = () => {
    //     const location = window.navigator && window.navigator.geolocation;
    //     if (location) {
    //         location.getCurrentPosition((position) => {
    //             this.getWeather(position.coords.latitude, position.coords.longitude);
    //         }, (error) => {
    //             this.setState({
    //                 weather: false
    //             })
    //         })
    //     }
    // };
    //
    // getWeather = async (lat, lng) => {
    //     const APIkey = "a1db4d59a9e40e3b027a7273534498ae";
    //     const getURL = "https://api.openweathermap.org/data/2.5/weather?lat=" + lat
    //         + "&lon=" + lng + "&units=metric&APPID=" + APIkey;
    //     fetch(getURL,{
    //         method: 'get'
    //     }).then(r => r.json())
    //         .then(data => {
    //             this.setState({
    //                 weather: data
    //             });
    //             // console.log("Success weather", data);
    //         })
    //         .catch(e => console.log('err weather'))
    //
    //
    // };

    render() {
        let dataMenu = Config.menu && Config.menu.listMenu ? Config.menu.listMenu : [];
        let groupMenu = [];
        dataMenu.forEach((menu) => {
            if (!!menu.Permission) {
                const checkExistGroupMenu = groupMenu.filter((item) => {
                    return item.MenuGroupID === menu.MenuGroupID;
                });
                if (!checkExistGroupMenu.length) {
                    const group = {
                        MenuGroupID: menu.MenuGroupID ? menu.MenuGroupID : "",
                        MenuGroupName: menu.MenuGroupName ? menu.MenuGroupName : "",
                        MenuGroupIcon: menu.MenuGroupIcon ? menu.MenuGroupIcon : ""
                    };
                    groupMenu.push(group);
                }
            }
        });
        dataMenu = dataMenu.filter(i => !!i.Permission);

        return(
            <div className="dashboard-container">
                <div className={'dashboard-container--list'}>
                    {groupMenu && groupMenu.map((itemGroup, idxGroup) => {
                        const listmenu = dataMenu.filter(menu => menu.MenuGroupID === itemGroup.MenuGroupID);
                        return (
                            <React.Fragment key={idxGroup}>
                                <div className="dashboard-container--group-name">{itemGroup.MenuGroupName || ""}</div>
                                <Row key={idxGroup}
                                    className={'dashboard-container--group'}>
                                    {listmenu && listmenu.map((item, idx) => {
                                        return (
                                            <ItemMenu key={idx} index={idx} data={item}/>
                                        )
                                    })}
                                </Row>
                            </React.Fragment>
                        )
                        })
                    }
                </div>
            </div>
        )
    }
}

class ItemMenu extends React.Component{

    onLink = () => {
        const {data} = this.props;
        if(!data.FormID) return;
        const parameter = data.Parameters ? JSON.parse(data.Parameters) : null;
        const params = {
            menu: {MenuID: data.MenuID, FormID: data.FormID, FormIDPermission: data.FormIDPermission},
            ...(parameter ? {...parameter} : {})
        };
        browserHistory.push({
            pathname: Config.getRootPath() + data.FormID,
            state: params
        });
    };

    render(){
        const {data} = this.props;
        const name = data.FormDesc || data.MenuName || data.name;
        return(
            <div className={'dashboard-container--wrapper-item'} onClick={this.onLink} title={name}>
                <Animated className={'dashboard-container--item'}
                          animationIn="flipInX"
                          animationInDuration={1000}
                          isVisible={true}>
                    <div className={"dashboard-container--item__icon"}>
                        {/*<Image src={data.icon || this._getResourceImage(data)}*/}
                        {/*/>*/}
                        <IconMenu type={'erp'} name={data?.MenuID} />
                    </div>
                    <span className={'dashboard-container--item__name'}>
                        {Config.sub_text(name, 25)}
                    </span>
                </Animated>
            </div>
        )
    }
}

export default connect(null,
    (dispatch) => ({
        homeActions: bindActionCreators(homeActions, dispatch)
    })
)(Dashboard);
