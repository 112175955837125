/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/14/2020
 * @Example
 */

import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, FormGroup, Row } from "react-bootstrap";
import InlineSVG from "react-inlinesvg";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../config";
import * as generalActions from "../../../redux/general/general_actions";
import { Combo } from "../form-material";
import Modal from "../modal/modal";
import Icons from "./../icons";
import ButtonGeneral from "./button-general";

class ButtonExportExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnExportStatus: true,
            openPopup: false,
            loading: false,
            readOnly: 0,
            downloadURL: "",
            downloadExt: "",
            downloadName: "",
            cboGeneralReport: []
        };
        this.report = null;
    }

    onExport = (isPDF = 0, isDocx = 0) => {
        const { ReportTypeID = "", StoreParams, customReportFileName } = this.props;
        const { ReportName, URL: TemplateURL = "", FileExt = "" } = this.report;
        let ReportFileName = ReportName;
        if ((isPDF || isDocx) && customReportFileName) {
            ReportFileName = typeof customReportFileName === "function" ? customReportFileName(StoreParams) : customReportFileName;
        }
        let params = {
            ReportTypeID,
            TemplateURL,
            isDocx,
            isPDF,
            FileExt,
            ReportFileName,
            StoreParams: JSON.stringify(StoreParams)
        };
        this.setState({ loading: true });
        this.props.generalActions.exportExcelTemplate(params, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({
                    downloadURL: data.URL || "",
                    downloadExt: data.fileExt || "",
                    downloadName: data.fileName || "",
                }, () => {
                    let el = document.getElementById("linkExportExcel_1");
                    el.click();
                });
            }
        });
    }

    loadCboReports = () => {
        const { ReportTypeID, ModuleID } = this.props;
        const params = {
            ReportTypeID: ReportTypeID || "",
            ModuleID: ModuleID || "",
        };
        this.setState({ loading: true });
        this.props.generalActions.getReports(params, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ cboGeneralReport: data })
            }
        });
    };

    changeCboReports = (e) => {
        this.report = e.value;
        if (!_.isEmpty(e.value) && this.state.readOnly !== e.value.ReadOnly) {
            this.setState({ readOnly: e.value.ReadOnly })
        }
        this.setState({ btnExportStatus: false })
    };

    onClickShow = (e) => {
        const { onClick, ReportTypeID, ModuleID, getCboGeneralReport, paramsCboGeneralReportBeforce, exportBtnState } = this.props;
        if (_.isEmpty(exportBtnState)) this.props.generalActions.onSaveExportBtnState(this.state);
        if (onClick) onClick(e);
        if (this.state.readOnly !== 0) this.setState({ readOnly: 0 })
        this.setState({ openPopup: true }, () => {
            if (_.isEmpty(getCboGeneralReport) && _.isEmpty(paramsCboGeneralReportBeforce)) {
                this.loadCboReports();
            } else {
                const { ReportTypeID: ReportTypeIDBeforce = "", ModuleID: ModuleIDBeforce = "" } = paramsCboGeneralReportBeforce;
                if ((ReportTypeID !== ReportTypeIDBeforce || ModuleID !== ModuleIDBeforce)) {
                    this.loadCboReports();
                } else {
                    this.setState({ cboGeneralReport: getCboGeneralReport })
                }
            }
        });
    };

    onCloseModal = (e) => {
        //Close Modal and clear state and global
        const { exportBtnState } = this.props;
        this.report = null;
        this.setState({ ...exportBtnState });
    };

    render() {
        const { name, icon, color, variant, style, className, size, titlePopup, mode,
            disabled, buttonProps, modalProps, comboProps, typeButton } = this.props;
        const { openPopup, loading, readOnly, cboGeneralReport, btnExportStatus } = this.state;
        return (
            <React.Fragment>
                <ButtonGeneral
                    name={name}
                    icon={icon}
                    typeButton={typeButton || "export"}
                    color={color}
                    variant={variant}
                    style={{ textTransform: 'uppercase', ...style }}
                    className={className}
                    size={size}
                    disabled={disabled}
                    onClick={this.onClickShow}
                    {...buttonProps}
                />
                <Modal open={openPopup}
                    maxWidth={"sm"}
                    fullWidth={true}
                    position={"center"}
                    {...modalProps}>
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                            <div style={{ display: 'flex', alignItem: 'center' }}>
                                <InlineSVG src={require("./icons/export.svg")} />&nbsp;
                                <Typography variant={"h6"} className={"mgr10 text-uppercase"}>{titlePopup || Config.lang("ERP_Xuat_du_lieu")}</Typography>
                            </div>
                            <IconButton
                                aria-label={"close"}
                                size={"small"}
                                onClick={this.onCloseModal}
                            >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </Modal.Title>
                    <Modal.Content>
                        <FormGroup>
                            <Row>
                                <Col xs={12} sm={12} md={3} lg={3}>
                                    <FormLabel component={"label"} style={{ marginTop: 16 }}>{Config.lang("ERP_Mau_bao_cao")}</FormLabel>
                                </Col>
                                <Col xs={12} sm={12} md={9} lg={9}>
                                    <Combo
                                        // label={Config.lang("ERP_Mau_bao_cao")}
                                        dataSource={Config.storeDataSoureDevExtreme(cboGeneralReport)}
                                        displayExpr={"ReportName"}
                                        // valueExpr={"ReportID"}
                                        stylingMode={"underlined"}
                                        margin={"dense"}
                                        disabled={loading}
                                        loading={loading}
                                        value={this.report}
                                        required={true}
                                        shrink={true}
                                        itemRender={(e) => {
                                            if (!e) return null;
                                            return e.ReportID + ' - ' + e.ReportName;
                                        }}
                                        {...comboProps}
                                        onValueChanged={this.changeCboReports}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        {(mode !== 1 && readOnly === 0 && this.report?.FileExt !== "html") && <ButtonGeneral name={Config.lang("ERP_Xuat_Excel")}
                            color={"primary"}
                            variant={"outlined"}
                            typeButton={"excel-no-boder"}
                            disabled={loading || btnExportStatus}
                            style={{ textTransform: 'uppercase', float: 'right', margin: '10px 0' }}
                            size={"large"}
                            onClick={() => this.onExport()} />}
                        {readOnly === 0 && this.report?.FileExt === "html" && <ButtonGeneral name={Config.lang("ERP_Xuat_Word")}
                            color={"primary"}
                            variant={"outlined"}
                            typeButton={"word"}
                            disabled={loading || btnExportStatus}
                            style={{ textTransform: 'uppercase', float: 'right', margin: '10px 0' }}
                            size={"large"}
                            onClick={() => this.onExport(0, 1)} />}
                        <ButtonGeneral name={Config.lang("ERP_Xuat_PDF")}
                            color={"primary"}
                            variant={"outlined"}
                            typeButton={"pdf"}
                            disabled={loading || btnExportStatus}
                            className={"mgr10"}
                            style={{ textTransform: 'uppercase', float: 'right', margin: '10px 0' }}
                            size={"large"}
                            onClick={() => this.onExport(1)} />
                    </Modal.Content>
                </Modal>
                <a id={"linkExportExcel_1"} className={"hide"} download={this.state.downloadName}
                    href={this.state.downloadURL}>File</a>
            </React.Fragment>
        );
    }
}

ButtonExportExcel.defaultProps = {
    mode: 2,
};
ButtonExportExcel.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.any,
    color: PropTypes.string,
    variant: PropTypes.string,
    typeButton: PropTypes.string,
    style: PropTypes.any,
    className: PropTypes.string,
    size: PropTypes.any,
    disabled: PropTypes.bool,
    mode: PropTypes.oneOf([0, 1, 2]), //0: Excel, 1: PDF, 2: Excel + PDF

    titlePopup: PropTypes.string,
    ReportTypeID: PropTypes.string.isRequired,
    ModuleID: PropTypes.string.isRequired,
    StoreParams: PropTypes.any.isRequired,

    buttonProps: PropTypes.any,
    modalProps: PropTypes.any,
    comboProps: PropTypes.any,

    onClick: PropTypes.func,
    customReportFileName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ])
};

export default compose(connect(state => ({
    getCboGeneralReport: state.general.getCboGeneralReport,
    paramsCboGeneralReportBeforce: state.general.paramsCboGeneralReportBeforce,
    exportBtnState: state.general.exportBtnState,
}),
    dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
    }),
))(ButtonExportExcel);
