/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/08 16:37
 * @file src/components/not-permission/not-permission.js
 */

import React, {Component} from 'react';
import {Col, FormGroup} from "reactstrap";
import PageContainer from "../common/page-container/page-container";
import Config from "../../config";
import {browserHistory} from "react-router";

export default class ModuleNotFound extends Component {
    render() {

        return (
            <PageContainer>
                <FormGroup row>
                    <div className="error-page login mrgt50">
                        <div className="notfound">
                            <div className="notfound-404">
                                <div></div>
                                <h1>403</h1>
                            </div>
                            <FormGroup row className="text-center">
                                <Col>
                                    <h2>{Config.lang('ERP_Truy_cap_khong_hop_le')}</h2>
                                </Col>
                                <Col>
                                    {Config.lang('ERP_403_loi')}
                                </Col>
                            </FormGroup>
                            {/* eslint-disable */}
                            <a onClick={()=>{
                                browserHistory.push({
                                    pathname: Config.getRootPath() + 'dashboard',
                                    state: {
                                        mode: 'MSS'
                                    }
                                })
                            }}>
                                {Config.lang("ERP_Trang_chu")}
                            </a>
                            {/* eslint-enable */}
                        </div>
                    </div>
                </FormGroup>
            </PageContainer>
        )
    }
}
