import React from "react";
import PropTypes from "prop-types";
import {FormControl, FormHelperText, InputLabel, withStyles} from "@material-ui/core";
import { DateBox } from 'devextreme-react/date-box';
import { compose } from "redux";

const styles = theme => {
    return {
        labelDatePicker: {
            transform: 'translateY(7px) !important'
        }
    }
}

class DateBoxPicker extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            shrink: Array.isArray(props.value) ? props.value.length > 0 : !!props.value || props.shrink,
            value: props.value,
        };
    }

    onFocusIn = () => {
        this.setState({
            shrink: true,
        });
    };

    onFocusOut = () => {
        const {value} = this.state;
        this.setState({
            shrink: Array.isArray(value) ? value.length > 0 : !!value || this.props.shrink,
        });
    };

    onOpened = (e) => {
        const {onOpened} = this.props;
        if (onOpened) onOpened(e);
    };

    onClosed = (e) => {
        const {onClosed} = this.props;
        if (onClosed) onClosed(e);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    componentDidMount() {
        const {defaultValue, value} = this.props;
        if (defaultValue && !value) {
            this.setState({
                value: defaultValue
            })
        }
    }

    onValueChanged = (e) => {
        const {onValueChanged} = this.props;
        this.setState({
            value: e.value,
            shrink: Array.isArray(e.value) ? e.value.length > 0 : !!e.value || this.props.shrink
        });
        if (onValueChanged) onValueChanged(e);
    };

    render() {
        const {value, shrink} = this.state;
        let {label,className,disabled,onChange,width,style, stylingMode, margin, opened, min, max,
            onInput,placeholder,height,type,displayFormat,dateSerializationFormat, acceptCustomValue,
            InputLabelProps, dateBoxProps, openOnFieldClick, error, helperText, useMaskBehavior, showClearButton,
            required, hideIcon, styleForm, classes
        } = this.props;

        const variant = stylingMode === 'underlined' ? "standard" : (stylingMode === 'filled' ? 'filled' : 'outlined');
        let buttons = [
            {
                name: "clear"
            },
            {
                name: "dropDown"
            }
        ];

        if (!hideIcon) buttons.push({
            name: "iconcalendar",
            location: "before",
            options: {
                style: {color: "red"},
                icon: require('../../../assets/images/icon-calendar.svg'),
                stylingMode: 'text',
            }
        });

        return (
            <React.Fragment>
                <FormControl error={!!error && !disabled && !value}
                             className={className}
                             variant={variant}
                             disabled={disabled}
                             margin={margin ? margin : "dense"}
                             style={styleForm}
                             fullWidth={true}>
                    {label && <InputLabel
                        className={classes.labelDatePicker}
                        required={required}
                        shrink={shrink}
                        disabled={disabled}
                        {...InputLabelProps}
                    >
                        {label}
                    </InputLabel>}
                    <DateBox
                        acceptCustomValue={acceptCustomValue}
                        buttons={buttons}
                        stylingMode={stylingMode ? stylingMode : "outlined"}
                        onFocusOut={this.onFocusOut}
                        onFocusIn={this.onFocusIn}
                        onOpened={this.onOpened}
                        onClosed={this.onClosed}
                        openOnFieldClick={typeof openOnFieldClick !== "undefined" ? openOnFieldClick : true}
                        disabled={disabled}
                        onChange={onChange}
                        onValueChanged={this.onValueChanged}
                        width={width}
                        height={height}
                        style={style}
                        {...typeof opened !== "undefined" ? {opened: opened} : {}}
                        onInput={onInput}
                        placeholder={placeholder}
                        value={value}
                        min={min}
                        max={max}
                        pickerType={"calendar"}
                        showClearButton={showClearButton}
                        isValid={!error || disabled || !!value}
                        type={type ? type : "date"}
                        useMaskBehavior={typeof useMaskBehavior !== "undefined" ? useMaskBehavior : true}
                        displayFormat={displayFormat ? displayFormat : "dd/MM/yyyy"}
                        dateSerializationFormat={dateSerializationFormat ? dateSerializationFormat : "yyyy-MM-dd"}
                        {...dateBoxProps}
                    />
                    <FormHelperText>{typeof error === "string" && !value ? error : helperText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        );
    }
}

DateBoxPicker.propTypes = {
    acceptCustomValue: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    width: PropTypes.any,
    style: PropTypes.object,
    opened: PropTypes.bool,
    onInput: PropTypes.func,
    placeholder: PropTypes.string,
    height: PropTypes.any,
    value: PropTypes.any,
    type: PropTypes.string,
    error: PropTypes.any,
    helperText: PropTypes.bool,
    defaultValue: PropTypes.any,
    dateBoxProps: PropTypes.any,
    useMaskBehavior: PropTypes.bool,
    showClearButton: PropTypes.bool,
    required: PropTypes.bool,
    min: PropTypes.any,
    max: PropTypes.any,
    hideIcon: PropTypes.bool,
    styleForm: PropTypes.any,

    openOnFieldClick: PropTypes.bool,
    stylingMode: PropTypes.string,
    displayFormat: PropTypes.any,
    dateSerializationFormat: PropTypes.string,

    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    onChange: PropTypes.func,
    onValueChanged: PropTypes.func
};

export default compose(withStyles(styles))(DateBoxPicker);