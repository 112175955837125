/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/20/2019
 * @Example
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";
import Fab from "@material-ui/core/Fab";
import InlineSVG from "react-inlinesvg";
import ReactLoading from "react-loading";
import withStyles from "@material-ui/core/styles/withStyles";
import Icons from "../icons";
import Config from "../../../config/index";
import _ from "lodash";

// const hexToRGB = (hex, alpha) => {
//     const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
//     if (alpha) {
//         return r + ", " + g + ", " + b + ", " + alpha;
//     } else {
//         return r + ", " + g + ", " + b;
//     }
// };
const styles = theme => ({
    btn_success: {
        '&:not(.btn-custom)': {
            '&.MuiButton-contained:not(.Mui-disabled)': {
                backgroundColor: theme.palette.success.main,
                color: '#FFFFFF',//theme.palette.getContrastText(theme.palette.success.main),
                '& svg': {
                    '& path, circle, line': {
                        // fill: theme.palette.getContrastText(theme.palette.success.main),
                        // stroke: theme.palette.getContrastText(theme.palette.success.main)
                        fill: '#FFFFFF',
                        stroke: '#FFFFFF'
                    }
                },
                '&:hover': {
                    backgroundColor: theme.palette.success.main,
                    opacity: 0.7
                }
            },
            '&.MuiButton-outlined': {
                border: '1px solid ' + theme.palette.success.main
            }
        },
        '&': {
            backgroundColor: 'transparent',
            color: theme.palette.success.main,
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.success.main,
                    stroke: theme.palette.success.main
                }
            },
            '&:hover': {
                // backgroundColor: 'rgba(' + hexToRGB(theme.palette.success.main, 0.17) + ')',
                backgroundColor: theme.palette.hover.main,
            }
        }
    },
    btn_info: {
        '&:not(.btn-custom)': {
            '&.MuiButton-contained': {
                backgroundColor: theme.palette.info.main,
                color: '#FFFFFF',
                '& svg': {
                    '& path, circle, line': {
                        fill: '#FFFFFF',
                        stroke: '#FFFFFF'
                    }
                },
                '&:hover': {
                    backgroundColor: theme.palette.info.main,
                    opacity: 0.7
                }
            },
            '&.MuiButton-outlined': {
                border: '1px solid ' + theme.palette.info.main
            }
        },
        '&': {
            backgroundColor: 'transparent',
            color: theme.palette.info.main,
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.info.main,
                    stroke: theme.palette.info.main
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.hover.main,
            }
        }
    },
    btn_warning: {
        '&:not(.btn-custom)': {
            backgroundColor: theme.palette.warning.main,
            color: "white",
            '& svg': {
                '& path, circle, line': {
                    fill: "white",
                    stroke: "white"
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
                opacity: 0.7
            }
        },
        '&': {
            backgroundColor: 'transparent',
            color: theme.palette.warning.main,
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.warning.main,
                    stroke: theme.palette.warning.main
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.hover.main,
            }
        }
    },
    btn_danger: {
        '&:not(.btn-custom)': {
            '&.MuiButton-contained': {
                backgroundColor: theme.palette.danger.main,
                color: '#FFFFFF',
                '& svg': {
                    '& path, circle, line': {
                        fill: '#FFFFFF',
                        stroke: '#FFFFFF'
                    }
                },
                '&:hover': {
                    backgroundColor: theme.palette.danger.main,
                    opacity: 0.7
                }
            },
            '&.MuiButton-outlined': {
                border: '1px solid ' + theme.palette.danger.main
            }
        },
        '&': {
            backgroundColor: 'transparent',
            color: theme.palette.danger.main,
            '& svg': {
                '& path, circle, line': {
                    fill: theme.palette.danger.main,
                    stroke: theme.palette.danger.main
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.hover.main,
            }
        }
    },
    centerIcon: {
        display: 'flex',
        verticalAlign: 'middle',
        alignItems: 'center'
    },
    hoverIcon: {
        visibility: 'hidden',
        opacity: 0,
        transition: '.4s',
    },
    reverseIcon: {
        '&:hover': {
            '& span.hoverIcon': {
                visibility: 'visible',
                opacity: '100%'
            }
        },
        '& span': {
            // display: 'flex',
            flexDirection: 'row-reverse'
        }
    },
    colorEdit: {
        visibility: 'visible',
        '& path': {
            fill: `${theme.palette.info.main} !important`
        }
    },
    primaryColor: {
        '& path': {
            fill: theme.palette.primary.main,
        }
    },
    editName: {
        marginRight: 10,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    notiNumber: {
        position: 'absolute',
        top: -5,
        right: -5,
        width: 16,
        height: 16,
        color: 'white',
        fontSize: '10px',
        borderRadius: '100%',
        backgroundColor: theme.palette.danger.main
    }
});
class ButtonGeneral extends Component {

    getIconButton = () => {
        const { typeButton, icon, typeIcon, iconProps, classes, disabled } = this.props;
        let classIcon = iconProps && iconProps.className ? iconProps.className : "";
        const { variant } = this.getButtonDefaultTypes();
        const outlinedColorIcon = variant === "outlined" ? { className: classes.primaryColor } : {};
        let cIcon = "";
        let _icon = "";
        switch (typeButton) {
            case "add":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg") && icon
                    ? <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/add.svg')} {...iconProps} style={{ width: 14, height: 14 }} />
                    : <Icons name={"add"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "edit":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg")
                    ? <InlineSVG {...(outlinedColorIcon)} src={icon ? icon : require('./icons/edit.svg')} {...iconProps} />
                    : <Icons name={"edit"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "delete":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg") && icon
                    ? <InlineSVG className={'btn-icon-svg'} src={icon} {...iconProps} style={{ width: 14, height: 14 }} />
                    : <Icons name={"delete"} width={18} height={18} style={{ marginTop: -4 }} {...iconProps} />;
                return { icon: _icon };
            case "save":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg") && icon
                    ? <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/save.svg')} {...iconProps} style={{ width: 14, height: 14 }} />
                    : <Icons name={"save"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "notsave":
                classIcon = classIcon ? classIcon : "";
                cIcon = icon ? icon : "glyphicon glyphicon-floppy-remove";
                return { icon: <span {...iconProps} className={classIcon + " " + cIcon} />, colorBtn: 'secondary' };
            case "excel":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg") && icon
                    ? <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/excel.svg')} {...iconProps} style={{ width: 14, height: 14 }} />
                    : <Icons name={"export"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "help":
                cIcon = icon ? icon : "fas fa-question-circle";
                return { icon: <i {...iconProps} className={classIcon + " " + cIcon} /> };
            case "close":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg") && icon
                    ? <InlineSVG className={'btn-icon-svg'} src={icon} {...iconProps} style={{ width: 14, height: 14 }} />
                    : <Icons name={"cancel"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "filter":
                cIcon = icon ? icon : "fas fa-filter";
                return { icon: <i {...iconProps} className={classIcon + " " + cIcon} /> };
            case "search":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg") && icon
                    ? <InlineSVG className={'btn-icon-svg'} src={icon} {...iconProps} style={{ width: 14, height: 14 }} />
                    : <Icons name={"search"} width={18} height={18} {...iconProps} />;
                return { icon: _icon };
            case "approve":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg") && icon
                    ? <InlineSVG className={'btn-icon-svg'} src={icon} {...iconProps} style={{ width: 14, height: 14 }} />
                    : <Icons name={"approval"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "view":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg") && icon
                    ? <InlineSVG className={'btn-icon-svg'} src={icon} {...iconProps} style={{ width: 14, height: 14 }} />
                    : <Icons name={"view"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "send":
                cIcon = icon ? icon : "fas fa-paper-plane";
                return { icon: <i {...iconProps} className={classIcon + " " + cIcon} /> };
            case "custom_edit":
                _icon = !disabled && <span className={`${classes.centerIcon}  ${!Config.isMobile ? classes.hoverIcon : ''} hoverIcon`}><InlineSVG className={`${classes.colorEdit}`} src={icon ? icon : require('./icons/edit.svg')} {...iconProps} /></span>;
                return { icon: _icon };
            case "attachment":
                _icon = <InlineSVG className={classes.primaryColor} src={icon ? icon : require('./icons/attachment.svg')} {...iconProps} style={{ width: 14, height: 14 }} />
                return { icon: _icon };
            case "export":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg")
                    ? <InlineSVG {...(outlinedColorIcon)} src={icon ? icon : require('./icons/export.svg')} {...iconProps} />
                    : <Icons name={"export"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "pdf":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg")
                    ? <InlineSVG src={icon ? icon : require('./icons/pdf.svg')} {...iconProps} />
                    : <Icons name={"export"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "excel-no-boder":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg")
                    ? <InlineSVG src={icon ? icon : require('./icons/excel-no-border.svg')} {...iconProps} />
                    : <Icons name={"export"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "word":
                _icon = (typeof typeIcon === "undefined" || typeIcon === "svg")
                    ? <InlineSVG src={icon ? icon : require('./icons/word.svg')} {...iconProps} />
                    : <Icons name={"export"} width={20} height={20} {...iconProps} />;
                return { icon: _icon };
            case "W06F0004":
                _icon = <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/W06F0004.svg')} {...iconProps} style={{ width: 20, height: 20 }} />
                return { icon: _icon };
            case "confirm_delivery":
                _icon = <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/confirm-delivery.svg')} {...iconProps} style={{ width: 20, height: 20 }} />
                return { icon: _icon };
            case "confirm_list":
                _icon = <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/confirm-list.svg')} {...iconProps} style={{ width: 25, height: 25 }} />
                return { icon: _icon };
            default:
                return "";
        }
    };

    getButtonDefaultTypes = () => {
        const { typeButton, variant, color } = this.props;
        let _variant = variant, _color = color;
        switch (typeButton) {
            case "add":
                _color = color ? color : "primary";
                _variant = variant ? variant : "contained";
                break;
            case "save":
                _color = color ? color : "info";
                _variant = variant ? variant : "contained";
                break;
            case "edit":
                _color = color ? color : "primary";
                _variant = variant ? variant : "outlined";
                break;
            case "cancel":
                _color = color ? color : "primary";
                _variant = variant ? variant : "outlined";
                break;
            case "attachment":
                _color = color ? color : "primary";
                _variant = variant ? variant : "outlined";
                break;
            case "export":
                _color = color ? color : "primary";
                _variant = variant ? variant : "outlined";
                break;
            case "excel":
                _color = color ? color : "primary";
                _variant = variant ? variant : "outlined";
                break;
            case "pdf":
                _color = color ? color : "primary";
                _variant = variant ? variant : "outlined";
                break;
            default:
                break;
        }

        return { color: _color, variant: _variant };
    };

    onClick() {
        const { loading } = this.props;
        if (this.props.onClick && !loading) this.props.onClick();
    }

    render() {
        const { classes, fab, name, style, className, disabled, margin, loading, reverseIcon, typeButton, notiNumber, ...props } = this.props;
        const listColorText = ["success", "info", "warning", "danger"];
        const { variant, color } = this.getButtonDefaultTypes();
        const { icon, colorBtn } = this.getIconButton();
        const _color = color ? color : colorBtn;
        const styleFab = {
            backgroundColor: '#fff'
        };
        if (props.typeButton) delete props.typeButton;
        if (props.typeIcon) delete props.typeIcon;
        if (props.color) delete props.color;
        let _className = "btn-action";
        if (className) {
            _className += " " + className;
        }
        if (loading) {
            _className += " btn-loading";
        }
        if (variant === "custom") {
            _className += " btn-custom";
        }
        const custom_btn = listColorText.indexOf(_color) > -1 ? classes["btn_" + _color] : "";
        const reverseIconClass = reverseIcon ? classes.reverseIcon : "";
        const btnIcon = reverseIcon ? <span className={classes.editName}
            {...(!disabled ? { style: { color: '#0095FF' } } : {})}>{name}</span> : <span style={{ fontWeight: 700 }} className={icon ? "mgl5" : ""}>{name}</span>;
        return (
            <React.Fragment>
                {!fab && <ButtonBasic  {...props}
                    disabled={disabled}
                    style={style}
                    {...(listColorText.indexOf(_color) < 0 ? { color: _color } : {})}
                    className={`${custom_btn ? custom_btn + " " : ""} ${_className} ${reverseIconClass}`}
                    onClick={() => this.onClick()}
                    {...(variant !== "custom" ? { variant: variant ? variant : "outlined" } : { variant: "text" })}
                    margin={margin ? margin : "dense"}
                >
                    {(!loading && icon) && icon}
                    {loading && <ReactLoading className={name ? "mgr5" : ""} type={'spokes'} height={16} width={16} color={_color !== 'inherit' ? '#fafafa' : '#3c3c3c'} />}
                    {name && btnIcon}
                    {!name && <span style={{ marginLeft: -5 }}>&nbsp;</span>}
                    {_.isNumber(notiNumber) && notiNumber >= 0 && <div className={classes.notiNumber}>{notiNumber}</div>}
                </ButtonBasic>}
                {fab && <Fab
                    {...props}
                    disabled={disabled}
                    style={props.style ? props.style : _color ? {} : styleFab}
                    {...(listColorText.indexOf(_color) < 0 ? { color: _color } : {})}
                    className={(custom_btn ? custom_btn + " " : "") + className + (loading ? " btn-loading" : "")}
                    onClick={() => this.onClick()}
                    variant={variant ? variant : 'extended'}
                >
                    {(!loading && icon) && icon}
                    {loading && <ReactLoading className={name ? "mgr5" : ""} type={'spokes'} height={16} width={16} color={_color !== 'inherit' ? '#fafafa' : '#3c3c3c'} />}
                    {name && btnIcon}
                    {!name && <span style={{ marginLeft: -5 }}>&nbsp;</span>}
                </Fab>}
            </React.Fragment>
        );
    }
}

ButtonGeneral.propTypes = {
    fab: PropTypes.bool,
    style: PropTypes.any,
    typeButton: PropTypes.string,
    typeIcon: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    margin: PropTypes.string,
    className: PropTypes.string,
    iconProps: PropTypes.any,
    loading: PropTypes.bool,

    onClick: PropTypes.func,
    notiNumber: PropTypes.number
};

export default withStyles(styles)(ButtonGeneral);